import React from 'react';
import { Tab, Tabs as DefaultTabs } from '@material-ui/core';

interface CustomerTabsProps {
  tab: number;
  handleTabChange(value: number): void;
}

const Tabs: React.FC<CustomerTabsProps> = ({ tab, handleTabChange }) => {
  return (
    <DefaultTabs value={tab} onChange={(event, value) => handleTabChange(value)}>
      <Tab label="Cadastro" />
      <Tab label="Pedidos" />
      <Tab label="Títulos" />
    </DefaultTabs>
  );
};
export default Tabs;
