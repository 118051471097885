import React, { useMemo } from 'react';
import { ListItem, makeStyles, Typography } from '@material-ui/core';
import { Commission } from 'types/Commission';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import CommissionListItemInfo from './CommissionListInfo';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    minHeight: 135,
    paddingTop: 15,
  },
  listItemRazao: {
    marginBottom: 10,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
  },
  label: {
    width: 80,
    display: 'inline-flex',
    gap: '5px',
  },
  content: {
    display: 'grid',
  },
}));

interface CommissionListItemProps {
  commission: Commission;
}

const CommissionListItem: React.FC<CommissionListItemProps> = ({ commission }) => {
  const classes = styles();

  const formattedCommissionValue = useMemo(() => moneyFormat(commission.F13_BASE), [commission]);
  const formattedDebitValue = useMemo(() => moneyFormat(commission.F13_VALOR), [commission]);

  return (
    <ListItem button className={classes.listItem}>
      <CommissionListItemInfo commission={commission} />

      <Typography className={classes.listItemRazao} variant="h6">
        {commission.G05_RAZAO}
      </Typography>

      <div className={classes.content}>
        <Typography variant="body2">
          <span className={classes.label}>Pedido</span>
          {commission.F13_PEDIDO}
        </Typography>

        <Typography variant="body2">
          <span className={classes.label}>Data</span>
          {commission.F13_DTCOMISSAO}
        </Typography>

        <Typography variant="body2">
          <span className={classes.label}>Base</span>
          {formattedCommissionValue}
        </Typography>

        <Typography variant="body2">
          <span className={classes.label}>Valor</span>
          <strong>
            {formattedDebitValue} ({percentFormat(commission.F13_ALIQUOTA)})
          </strong>
        </Typography>
      </div>
    </ListItem>
  );
};

export default CommissionListItem;
