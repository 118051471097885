import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

export type ListStyleOptions = 'grid' | 'detail';

interface ListStyleContextValue {
  listStyle: ListStyleOptions;
  setListStyle: Dispatch<SetStateAction<ListStyleOptions>>;
}

const ListStyleContext = createContext<ListStyleContextValue>({} as ListStyleContextValue);
export const OrdersProvider = ListStyleContext.Provider;

interface ListStyleProviderProps {
  children: ReactNode;
}

const ListStyleProvider: React.FC<ListStyleProviderProps> = ({ children }) => {
  const [listStyle, setListStyle] = useState<ListStyleOptions>('detail');

  return <OrdersProvider value={{ listStyle, setListStyle }}>{children}</OrdersProvider>;
};

export function useListStyle(): ListStyleContextValue {
  return useContext(ListStyleContext);
}

export default ListStyleProvider;
