import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { CustomerEn } from 'types/customer';
import { api } from 'services/api';
import Appbar from 'components/appbar/Appbar';
import OrderTabs from '../new/tabs/OrderTabs';
import OrderViewTotals from './viewTotal/OrderViewTotals';
import { useParams } from 'react-router-dom';
import OrderAction from './OrderAction';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { OrderView as Order } from 'types/orderView';
import OrderForm from './OrderForm';
import OrderProducts from './OrderProducts';

const styles = makeStyles(theme => ({
  action: {
    margin: '40px 0 20px',
  },
  button: {
    marginRight: 10,
  },
  content: {
    padding: '45px 30px 0',
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '40px 20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '45px 20px 0',
    },
  },
  paper: {
    width: '100%',
    margin: '0 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0px',
    },
  },
  title: {
    padding: 20,
    borderBottom: '1px solid #ccc',
  },
  tabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
  },
  appbar: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

type OrderViewParams = {
  companyId: string;
  orderId: string;
};

const OrderView: React.FC = () => {
  const classes = styles();
  const [showTotal, setShowTotal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { orderId, companyId } = useParams<OrderViewParams>();
  const [order, setOrder] = useState<Order | null>(null);
  const [customer, setCustomer] = useState<CustomerEn | null>(null);

  useEffect(() => {
    if (!order?.idCliente) {
      return;
    }

    api
      .get('cliente/load', { params: { id: order.idCliente } })
      .then(response => {
        setCustomer(response.data.customer);
      })
      .catch(err => console.error(err));
  }, [order]);

  useEffect(() => {
    api
      .get('/vendas/loadPedido', { params: { idPedido: orderId, idEmpresa: companyId } })
      .then(response => {
        setOrder(response.data.pedido);
      })
      .catch(err => console.error(err));
  }, [orderId, companyId]);

  function handleTabChange(value: number) {
    setTabIndex(value);
  }

  function handleCopy() {
    history.push('/new-order');
  }

  function redirectToOrderPrint() {
    history.push(`/orders/print/${companyId}/${orderId}`);
  }

  function handleShowTotal() {
    setShowTotal(state => !state);
  }

  return (
    <>
      <Appbar
        title={'Vendas'}
        ActionsComponent={
          <OrderAction
            redirectToOrderPrint={redirectToOrderPrint}
            handleCopy={handleCopy}
            handleShowTotal={handleShowTotal}
          />
        }
        Tab={<OrderTabs tabvalue={tabIndex} handleTabChange={handleTabChange} customer={customer} />}
      />

      {showTotal && order && <OrderViewTotals order={order} onExited={handleShowTotal} />}

      {!order ? (
        <Loading />
      ) : (
        <div className={classes.paper}>
          {tabIndex === 0 ? <OrderForm order={order} customer={customer} /> : <OrderProducts products={order.items} />}
        </div>
      )}
    </>
  );
};

export default OrderView;
