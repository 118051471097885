import React, { useMemo } from 'react';
import { makeStyles, ListItem, Typography } from '@material-ui/core';
import { percentFormat } from 'helpers/NumberFormat';

const styles = makeStyles({
  listItem: {
    padding: '5px 0',
    display: 'block',
  },
});

interface OrderDiscountItemProps {
  productDiscounts: any;
}

const OrderDiscountItem: React.FC<OrderDiscountItemProps> = ({ productDiscounts }) => {
  const classes = styles();

  const minDiscount = useMemo(() => percentFormat(productDiscounts.minDiscount), [productDiscounts]);
  const maxDiscount = useMemo(() => percentFormat(productDiscounts.maxDiscount), [productDiscounts]);
  const commission = useMemo(() => percentFormat(productDiscounts.commission), [productDiscounts]);
  const minQuantity = useMemo(() => productDiscounts.minQuantity, [productDiscounts]);
  const fator = useMemo(() => productDiscounts.fator, [productDiscounts]);
  const secondUnit = useMemo(() => productDiscounts.secondUnit, [productDiscounts]);

  return (
    <ListItem className={classes.listItem}>
      <Typography>
        Desconto de {minDiscount} até {maxDiscount}
      </Typography>
      <Typography>Comissão: {commission}</Typography>

      {minQuantity > 0 && (
        <Typography>
          Quantidade mínima:
          <strong>
            {minQuantity / fator} {secondUnit}
          </strong>
        </Typography>
      )}
    </ListItem>
  );
};

export default OrderDiscountItem;
