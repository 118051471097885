import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ProductPriceItem from './ProductPriceItem';
import { useOrder } from '../hooks/useOrder';

interface ProductListPricesProps {
  productPrices: any[];
}

const ProductListPrices: React.FC<ProductListPricesProps> = ({ productPrices }) => {
  const { setProductToAdd } = useOrder();
  return (
    <>
      <Typography variant="caption">Escolha um produto abaixo</Typography>
      <List component="nav">
        {productPrices.map(productPrice => {
          return (
            <ProductPriceItem
              productPrice={productPrice}
              key={productPrice.productId}
              handleProductSelectToOrder={setProductToAdd}
            />
          );
        })}
      </List>
    </>
  );
};

export default ProductListPrices;
