import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDialog } from 'components/dialogs/Dialog';
import { Customer } from 'types/customer';

const styles = makeStyles({
  listItem: {
    marginBottom: 7,
    display: 'grid',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  label: {
    '& > span': {
      width: 80,
      display: 'inline-block',
    },
  },
});

interface CustomerSearchItemProps {
  customer: any;
  handleSetCustomer(customer: Customer): void;
}

const CustomerSearchItem: React.FC<CustomerSearchItemProps> = ({ customer, handleSetCustomer }) => {
  const classes = styles();
  const dialog = useDialog();

  function handleClick() {
    handleSetCustomer(customer);
    dialog.handleClose();
  }

  return (
    <ListItem onClick={handleClick} className={classes.listItem} button>
      <Typography gutterBottom>
        {customer.idCliente} - {customer.razao}
      </Typography>
      <Typography className={classes.label} variant="body2" color="textSecondary">
        <span>CNPJ</span>
        {customer.cnpj}
      </Typography>
      <Typography className={classes.label} variant="body2" color="textSecondary">
        <span>Cidade</span>
        {customer.cidade}
      </Typography>
      <Typography className={classes.label} variant="body2" color="textSecondary">
        <span>Endereço</span>
        {customer.endereco}
      </Typography>
    </ListItem>
  );
};

export default CustomerSearchItem;
