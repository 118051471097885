import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { NewOrder } from 'types/newOrder';
import { OrderProduct, Product } from 'types/product';
import { HandleAddOrEditProductParam } from '../Order';
import { OrderActions } from '../reducer/types';

export interface OrderContextValue {
  order: NewOrder;
  dispatch: Dispatch<OrderActions>;
  stock: any;
  setStock: Dispatch<SetStateAction<any>>;
  handleAddOrEditProduct(param: HandleAddOrEditProductParam): Promise<void>;
  handleItemDelete(): void;
  handleSelectProducts(orderProduct: OrderProduct): void;
  productsMix: any[];
  selectedProducts: Product[] | [];
  saving: boolean;
  setIsOpenedAddProductModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenedMixModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenedStockModal: Dispatch<SetStateAction<boolean>>;
  setProductToAdd: Dispatch<SetStateAction<Product | null>>;
  productToAdd: Product | null;
  setProductToEdit: Dispatch<SetStateAction<OrderProduct | null>>;
  productToEdit: OrderProduct | null;
  isOpenedMixModal: boolean;
}

const OrderContext = createContext<OrderContextValue>({} as OrderContextValue);
export const OrderProvider = OrderContext.Provider;
export const OrderConsumer = OrderContext.Consumer;

export function useOrder(): OrderContextValue {
  return useContext(OrderContext);
}
