import React, { FormEvent, useCallback, useState } from 'react';
import AddProductForm from './AddProductForm';
import AddProductAction from './AddProductAction';
import { api } from 'services/api';
import { useOrder } from '../hooks/useOrder';
import { AddProductProvider } from './hooks/useAddProduct';
import AddProductSearchForm from './AddProductSearchForm';
import { useFetchPrices } from './hooks/useFetchPrices';
import Dialog from 'components/dialogs/Dialog';

interface AddProductProps {
  onExited(): void;
}

let timer: NodeJS.Timeout;

const AddProduct: React.FC<AddProductProps> = ({ onExited }) => {
  const [showStock, setShowStock] = useState(false);
  const [productPrices, setProductPrices] = useState<any[]>([]);
  const [, setProductSearchLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { order } = useOrder();
  const { handleAddOrEditProduct, setProductToAdd, productToAdd: product } = useOrder();
  const { discounts, price, setDiscounts, setPrice } = useFetchPrices();

  const productPricesFetch = useCallback(
    (term: string) => {
      setProductSearchLoading(true);

      api
        .get('/vendas/searchProduct', {
          params: {
            idCliente: order.customer?.idCliente,
            serie: order.idRateio,
            search: term,
          },
        })
        .then(response => {
          setProductPrices(response.data.data || []);
        })
        .catch(err => console.error(err))
        .finally(() => setProductSearchLoading(false));
    },
    [order]
  );

  async function handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (!price) {
      return;
    }

    if (!product) {
      return;
    }

    await handleAddOrEditProduct({ product, discount, quantity, price });
  }

  function handleChange(term: string) {
    clearTimeout(timer);

    if (!term) {
      setProductPrices([]);
      return false;
    }

    if (term.length < 2) {
      return;
    }

    timer = setTimeout(() => productPricesFetch(term), 500);
  }

  function handleReset() {
    setPrice(null);
    setProductPrices([]);
    setDiscounts([]);
    setProductToAdd(null);
    setQuantity(0);
    setDiscount(0);
  }

  return (
    <AddProductProvider
      value={{
        quantity,
        setQuantity,
        discount,
        setDiscount,
        handleSubmit,
        handleReset,
        discounts,
        price,
      }}
    >
      <Dialog
        backAction={product ? () => setProductToAdd(null) : undefined}
        onExited={onExited}
        title="Adicionar produto"
        ComponentActions={
          <AddProductAction
            handleSubmit={handleSubmit}
            showStock={showStock}
            handleChange={() => setShowStock(state => !state)}
          />
        }
      >
        {product ? (
          <AddProductForm />
        ) : (
          <AddProductSearchForm productPrices={productPrices} handleChange={handleChange} showStock={showStock} />
        )}
      </Dialog>
    </AddProductProvider>
  );
};

export default AddProduct;
