import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';

interface OrderActionProps {
  handleShowTotal(): void;
  redirectToOrderPrint(): void;
  handleCopy(): void;
}

const OrderAction: React.FC<OrderActionProps> = ({ redirectToOrderPrint, handleCopy, handleShowTotal }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const showTotal = () => {
    handleShowTotal();
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Novo pedido">
        <IconButton component={Link} to="/new-order" color={'inherit'}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Menu onClose={() => setAnchorEl(null)} id="menu-order-action" anchorEl={anchorEl} open={Boolean(anchorEl)}>
        <MenuItem onClick={showTotal}>Ver total</MenuItem>
        <MenuItem onClick={redirectToOrderPrint}>Imprimir</MenuItem>
      </Menu>
      <IconButton color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
        <MoreIcon />
      </IconButton>
    </>
  );
};

export default OrderAction;
