import { makeStyles, Typography } from '@material-ui/core';
import { DefaultTheme } from '@material-ui/styles';
import React from 'react';
import { Commission } from 'types/Commission';

type CommissionListItemStyleProps = {
  color: string;
};

const styles = makeStyles<DefaultTheme, CommissionListItemStyleProps>({
  info: {
    display: 'inline-flex',
    gap: 5,
    alignItems: 'center',
  },
  statusColor: props => ({
    backgroundColor: props.color,
    color: '#fff',
    padding: '0 3px',
    borderRadius: 4,
  }),
});

interface CommissionListItemInfoProps {
  commission: Commission;
}

const colorByStatus = {
  A: '#6bc720',
  V: '#ff8100',
  P: '#17a2b8',
};

const status = {
  A: 'Aberto',
};

const CommissionListItemInfo: React.FC<CommissionListItemInfoProps> = ({ commission }) => {
  const classes = styles({ color: colorByStatus[commission.F13_SITUACAO] });

  return (
    <Typography component="p" variant="caption" className={classes.info}>
      <span>{commission.X04_FANTASIA}</span>
      <span>/</span>
      <span>{commission.F13_TIPOTITULO}</span>
      <span>/</span>
      <span className={classes.statusColor}>{status[commission.F13_SITUACAO]}</span>
    </Typography>
  );
};

export default CommissionListItemInfo;
