import React from 'react';
import { useApp } from 'hooks/useApp';
import { ListItemIcon, ListItemText, ListItem, ListItemProps, makeStyles, useTheme } from '@material-ui/core';
import CustomNavLink from './CustomNavLink';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    color: theme.palette.primary.contrastText,
  },
  listItemText: {
    fontSize: 16,
  },
  nested: (props: { nested: number }) => ({
    paddingLeft: props.nested ? theme.spacing(props.nested) : 16,
    borderLeft: '2px solid transparent',
    minHeight: 50,
    display: 'flex',
  }),
}));

interface SidebarItemProps extends ListItemProps {
  to?: string;
  text: string;
  nested?: number;
  icon: React.ReactElement;
  isLink?: boolean;
  onClick?: () => void | null;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  text,
  nested = 0,
  isLink = true,
  className,
  to,
  onClick,
  ...rest
}) => {
  const classes = useStyles({ nested });
  const app = useApp();
  const theme = useTheme();

  function handleClick() {
    if (app.isMobile || app.windowWidth <= 1280) app.handleOpenMenu();
  }

  return (
    <ListItem
      {...rest}
      button
      component={isLink ? CustomNavLink : 'div'}
      to={to}
      onClick={onClick || handleClick}
      className={`${classes.nested} ${className}`}
      style={{ paddingLeft: nested ? theme.spacing(nested) : 16 }}
    >
      <ListItemIcon className={classes.listItemIcon} style={{ color: 'white' }}>
        {icon}
      </ListItemIcon>
      <ListItemText classes={{ primary: classes.listItemText }} primary={text} />
    </ListItem>
  );
};

export default SidebarItem;
