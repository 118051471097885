import React from 'react';
import Customers from 'pages/customers/Customers';
import Dashboard from 'pages/dashboard/Dashboard';
import Home from 'components/home/Home';
import Login from 'pages/login/Login';
import Orders from 'pages/orders/Orders';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import Customer from 'pages/customers/view/Customer';
import WeeklyClosing from 'pages/weekly_closing/WeeklyClosing';
import OrderView from 'pages/orders/view/Order';
import OrderPrint from 'pages/orders/print/OrderPrint';
import MyAccount from 'pages/account/MyAccount';
import Commission from 'pages/commission/Commission';
import Error404 from 'components/error/Error404';
import Invoices from 'pages/invoices/Invoices';
import Order from 'pages/orders/new/Order';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<PublicRoute element={<Error404 />} />} />

      <Route path="/" element={<ProtectedRoute element={<Home />} />} />

      <Route path="/login" element={<PublicRoute element={<Login />} />} />

      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />

      <Route path="/customers" element={<ProtectedRoute element={<Customers />} />} />

      <Route path="/customers/:id" element={<ProtectedRoute element={<Customer />} />} />

      <Route path="/invoices" element={<ProtectedRoute element={<Invoices />} />} />

      <Route path="/orders" element={<ProtectedRoute element={<Orders />} />} />
      <Route path="/new-order" element={<ProtectedRoute element={<Order />} />} />
      <Route path="/orders/view/:companyId/:orderId" element={<ProtectedRoute element={<OrderView />} />} />
      <Route path="/orders/print/:companyId/:orderId" element={<ProtectedRoute element={<OrderPrint />} />} />

      <Route path="/weeklyclosing" element={<ProtectedRoute element={<WeeklyClosing />} />} />

      <Route path="/my-account" element={<ProtectedRoute element={<MyAccount />} />} />

      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />

      <Route path="/commission" element={<ProtectedRoute element={<Commission />} />} />
    </Routes>
  );
};

export default AppRoutes;
