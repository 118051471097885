import { Collapse } from '@material-ui/core';
import { Add, Assignment } from '@material-ui/icons';
import React from 'react';
import { SideBarCollapsible } from '../Sidebar';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';
import StoreIcon from '@material-ui/icons/Store';
import DoneIcon from '@material-ui/icons/Done';

interface SalesSidebarItemProps {
  handleCollapseClick(collapsible: keyof SideBarCollapsible, closeOthers?: boolean): void;
  collapsible: SideBarCollapsible;
}

const SalesSidebarItem: React.FC<SalesSidebarItemProps> = ({ handleCollapseClick, collapsible }) => {
  return (
    <>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('sale')}
        icon={<StoreIcon />}
        text="Vendas"
        opened={collapsible.sale}
      />
      <Collapse in={collapsible.sale}>
        <SidebarItem to="/orders" button text="Pedidos" icon={<Assignment />} nested={5} />
        <SidebarItem to="/new-order" button text="Novo pedido" icon={<Add />} nested={5} />
        <SidebarItem to="/weeklyclosing" button text="Fechamento" icon={<DoneIcon />} nested={5} />
      </Collapse>
    </>
  );
};

export default SalesSidebarItem;
