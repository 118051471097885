import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SearchLoading from 'components/loading/SearchLoading';
import OrderStockList from './list/OrderStockList';
import OrderStockAction from './OrderStockAction';
import OrderStockForm from './OrderStockForm';
import OrderStockSearch from './search/OrderStockSearch';
import { api } from 'services/api';
import { useOrder } from '../hooks/useOrder';
import { Product } from 'types/product';
import { useDispatch } from 'react-redux';
import { addProductToStock, updateProductFromStock } from 'store/modules/stock/actions';
import Dialog from 'components/dialogs/Dialog';
import { useSelector } from 'store/selector';
import { OrderStockProvider } from './hook/useOrderStock';

let timer: NodeJS.Timeout;

interface OrderStockProps {
  onExited: any;
}

const OrderStock: React.FC<OrderStockProps> = ({ onExited }) => {
  const [showStock, setShowStock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const { order } = useOrder();
  const [products, setProducts] = useState<Product[]>([]);
  const [term, setTerm] = useState('');
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState<number>(0);
  const stock = useSelector(state => state.stock);
  const [mode, setMode] = useState<'insert' | 'update'>('insert');

  useEffect(() => {
    if (stock.products.length === 0) {
      setShowStock(false);
    }
  }, [stock]);

  function fetchStockProducts(term: string) {
    setLoading(true);

    api
      .get('/vendas/searchProduct', {
        params: { search: term, idCliente: order.customer?.idCliente, idRateio: order.idRateio },
      })
      .then(response => {
        if (response.data.qtd <= 0) {
          return;
        }

        setProducts(response.data.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  function handleSearchProductStockChange(value: string) {
    setTerm(value);

    clearTimeout(timer);

    if (value.length < 2) {
      setProducts([]);
      return false;
    }

    timer = setTimeout(() => fetchStockProducts(value), 500);
  }

  function handleProductStockSelect(product: Product) {
    setSelectedProduct(product);
  }

  function handleAddProductToStock() {
    if (!order.customer) {
      return;
    }

    if (!selectedProduct) {
      return;
    }

    setQuantity(0);
    setSelectedProduct(null);
    setTerm('');
    setProducts([]);

    if (mode === 'insert') {
      dispatch(addProductToStock(selectedProduct, quantity));
      return;
    }

    dispatch(updateProductFromStock(selectedProduct.productId, quantity));
  }

  return (
    <OrderStockProvider value={{ mode, selectedProduct, setSelectedProduct, setMode }}>
      <Dialog
        onExited={onExited}
        title={'Incluir estoque'}
        ComponentActions={
          <OrderStockAction
            handleChange={() => setShowStock(!showStock)}
            showStock={showStock}
            handleAddProductToStock={handleAddProductToStock}
            selectedProduct={selectedProduct}
          />
        }
      >
        {!selectedProduct && (
          <>
            {!showStock ? (
              <>
                <TextField
                  autoFocus
                  onChange={e => handleSearchProductStockChange(e.target.value)}
                  value={term}
                  margin="normal"
                  label="Pesquisar produto"
                  placeholder="Digite o código do produto"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                {loading && <SearchLoading />}

                {products.length > 0 && (
                  <OrderStockSearch products={products} handleProductStockSelect={handleProductStockSelect} />
                )}
              </>
            ) : (
              <OrderStockList />
            )}
          </>
        )}
      </Dialog>

      {selectedProduct && (
        <Dialog
          hideBackdrop
          onExited={() => setSelectedProduct(null)}
          title="Estoque"
          ComponentActions={
            <OrderStockAction
              handleChange={() => setShowStock(!showStock)}
              showStock={showStock}
              handleAddProductToStock={handleAddProductToStock}
              selectedProduct={selectedProduct}
            />
          }
        >
          <OrderStockForm
            selectedProduct={selectedProduct}
            handleAddProductToStock={handleAddProductToStock}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </Dialog>
      )}
    </OrderStockProvider>
  );
};

export default OrderStock;
