import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { FilterProps } from '../Invoices';
import InvoicesFilterAction from '../InvoicesFilterAction';
import InvoicesFilterForm from './InvoicesFilterForm';

interface InvoicesFilterFormProps {
  handleFilterChange(name: string, value: any): void;
  filters: FilterProps[];
  handleSubmit(): void;
  onExited(): void;
  setInitialStateFilterValue(): void;
}

const InvoicesFilter: React.FC<InvoicesFilterFormProps> = ({
  handleFilterChange,
  filters,
  handleSubmit,
  onExited,
  setInitialStateFilterValue,
}) => {
  return (
    <Dialog
      ComponentActions={
        <InvoicesFilterAction setInitialStateFilterValue={setInitialStateFilterValue} handleSubmit={handleSubmit} />
      }
      onExited={onExited}
      maxWidth="sm"
      title="Pesquisa"
    >
      <InvoicesFilterForm filter={filters} handleFilterChange={handleFilterChange} handleSubmit={handleSubmit} />
    </Dialog>
  );
};

export default InvoicesFilter;
