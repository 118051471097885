import { ListItem, ListItemSecondaryAction, ListItemText, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const styles = makeStyles({
  root: {
    paddingBottom: 0,
  },
});

interface OrderViewTotalListTextProps {
  title: string;
  text: string;
}

const OrderViewTotalListText: React.FC<OrderViewTotalListTextProps> = ({ title, text }) => {
  const classes = styles();

  return (
    <ListItem classes={{ root: classes.root }}>
      <ListItemText>{title}</ListItemText>
      <ListItemSecondaryAction>
        <Typography variant="body1">{text}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default OrderViewTotalListText;
