import React, { useMemo } from 'react';
import List from '@material-ui/core/List';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Pagination from 'components/pagination/Pagination';
import InvoiceListItem from './InvoiceListItem';
import { usePagination } from 'providers/pagination';
import { Invoice } from 'types/invoice';
import { useListStyle } from 'providers/listStyle';

type Styles = {
  listStyle: string;
};

const styles = makeStyles<Theme, Styles>(theme => ({
  list: props => ({
    display: 'grid',
    gridTemplateColumns: props.listStyle === 'grid' ? 'repeat(auto-fill, minmax(400px, 1fr))' : '1fr',
    gridAutoRows: 'min-content',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: props.listStyle === 'grid' ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr',
    },
  }),
}));

interface InvoicesListProps {
  invoices: Invoice[];
}

type PaginationValues = {
  start: number;
  end: number;
};

const InvoiceList: React.FC<InvoicesListProps> = ({ invoices }) => {
  const { listStyle } = useListStyle();
  const classes = styles({ listStyle });
  const { rowsPerPage, page } = usePagination();

  const pagination = useMemo((): PaginationValues => {
    return {
      start: page * rowsPerPage,
      end: page * rowsPerPage + rowsPerPage,
    };
  }, [page, rowsPerPage]);

  return (
    <>
      <List className={classes.list} disablePadding>
        {invoices.slice(pagination.start, pagination.end).map(invoice => (
          <InvoiceListItem invoice={invoice} key={invoice.id} />
        ))}
      </List>
      <Pagination count={invoices.length} />
    </>
  );
};

export default InvoiceList;
