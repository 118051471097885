import React, { useMemo } from 'react';
import { ListItem, makeStyles, Typography } from '@material-ui/core';
import { Invoice } from 'types/invoice';
import { moneyFormat } from 'helpers/NumberFormat';
import InvoiceListItemInfo from './InvoiceListItemInfo';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    minHeight: 135,
    paddingTop: 15,
  },
  listItemRazao: {
    marginBottom: 10,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
  },
  label: {
    width: 80,
    display: 'inline-flex',
    gap: '5px',
  },
  label2: {
    display: 'flex',
  },
}));

interface InvoiceListItemProps {
  invoice: Invoice;
}

const InvoiceListItem: React.FC<InvoiceListItemProps> = ({ invoice }) => {
  const classes = styles();

  const formattedInvoiceValue = useMemo(() => moneyFormat(invoice.valor), [invoice]);
  const formattedDebitValue = useMemo(() => moneyFormat(invoice.saldo), [invoice]);

  return (
    <ListItem button className={classes.listItem}>
      <InvoiceListItemInfo invoice={invoice} />

      <Typography className={classes.listItemRazao} variant="h6">
        {invoice.idCliente} - {invoice.razao}
      </Typography>
      <Typography className={classes.label2} variant="caption">
        <span className={classes.label}> Vencimento:</span>
        {invoice.vencimento}
      </Typography>
      {invoice.saldo > 0 && (
        <Typography variant="caption">
          <span className={classes.label}>Saldo:</span>
          {formattedDebitValue}
        </Typography>
      )}
      <Typography>
        <span className={classes.label}>Valor:</span>
        <strong>{formattedInvoiceValue}</strong>
      </Typography>
    </ListItem>
  );
};

export default InvoiceListItem;
