import React from 'react';
import { Grid, MenuItem, TextField, Collapse } from '@material-ui/core';
import Operations from 'components/operacao/Operations';
import PaymentMethod from 'pages/orders/new/payment-method/PaymentMethod';
import PaymentTerms from 'components/payment-terms/PaymentTerms';
import CustomerData from '../CustomerData';
import { OrderView } from 'types/orderView';

interface OrderFormProps {
  order: OrderView;
  customer: any;
}

const OrderForm: React.FC<OrderFormProps> = ({ order, customer }) => {
  return (
    <>
      <Grid item xs={12} md={6} lg={5} xl={4}>
        <TextField
          disabled
          required
          fullWidth
          label="Cliente"
          variant="standard"
          placeholder="Informe o cliente"
          value={customer?.name ?? ''}
          margin="normal"
        />
      </Grid>

      {customer && (
        <CustomerData
          customerId={order.idCliente}
          street={customer.address}
          companyName={customer.name}
          documentNumber={customer.cnpj}
          city={customer.city}
        />
      )}

      <Grid item xl={4} md={6} lg={5} xs={12}>
        <Operations disabled idOperacao={order.idOperacao} />
      </Grid>

      <Grid item xl={4} md={6} lg={5} xs={12}>
        <TextField
          required
          disabled
          select
          variant="standard"
          label="Rateio"
          value={order.idRateio}
          fullWidth
          margin="normal"
        >
          <MenuItem value="4">4 - BRASIL</MenuItem>
          <MenuItem value="PD">PD - BRADESCO</MenuItem>
        </TextField>
      </Grid>

      <Collapse in={order.idOperacao === '001'}>
        <Grid item xl={4} md={6} lg={5} xs={12}>
          <PaymentMethod disabled idFormaPagamento={order.idFormaPagamento} />
        </Grid>

        <Grid item xl={4} md={6} lg={5} xs={12}>
          <PaymentTerms disabled idCondicaoPagamento={order.idCondicaoPagamento} />
        </Grid>

        <Grid item xl={4} md={6} lg={5} xs={12}>
          <TextField
            disabled
            autoComplete={'off'}
            variant="standard"
            label="% Desconto"
            name="desconto"
            margin="normal"
            value={order.desconto || ''}
            type="number"
            placeholder="Informe o desconto"
            fullWidth
          />
        </Grid>
      </Collapse>

      <Grid item xs={12} md={6} lg={5} xl={4}>
        <TextField
          disabled
          label="Observação"
          multiline
          minRows={4}
          margin="normal"
          variant="standard"
          value={order.observacao}
          fullWidth
        />
      </Grid>
    </>
  );
};

export default OrderForm;
