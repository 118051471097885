import React from 'react';
import { Typography, makeStyles, alpha, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '10px 5px',
    borderRadius: '4px',
    backgroundColor: alpha(theme.palette.secondary.main, 0.7),
  },
}));

interface CustomerDataProps {
  customerId?: string | null;
  companyName?: string;
  documentNumber?: string;
  street?: string;
  city?: string;
}

const CustomerData: React.FC<CustomerDataProps> = ({ city, companyName, customerId, documentNumber, street }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={5} xl={4}>
      <div className={classes.container}>
        <Typography variant="caption">
          {customerId}, {companyName}, {documentNumber}, {street},{city}
        </Typography>
      </div>
    </Grid>
  );
};

export default CustomerData;
