import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  message: {
    marginTop: 15,
  },
});

const InsideLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress color="primary" />
      <Typography color="textSecondary" variant="caption" className={classes.message}>
        Carregando
      </Typography>
    </div>
  );
};

export default InsideLoading;
