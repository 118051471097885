import React from 'react';
import { ListItem, Typography, makeStyles } from '@material-ui/core';
import { Product } from 'types/product';
import { useOrderStock } from '../hook/useOrderStock';

const styles = makeStyles({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
  },
});

interface OrderStockSearchItemProps {
  handleProductStockSelect(product: Product): void;
  product: Product;
}

const OrderStockSearchItem: React.FC<OrderStockSearchItemProps> = ({ handleProductStockSelect, product }) => {
  const classes = styles();
  const { setMode } = useOrderStock();

  function handleClick() {
    handleProductStockSelect(product);
    setMode('insert');
  }

  return (
    <ListItem className={classes.listItem} onClick={handleClick} button>
      <Typography variant="h5">
        {product.productId} - {product.description}
      </Typography>
      <Typography variant="caption">
        Embalagem:
        <strong>
          {product.secondUnit}/{product.fator}
        </strong>
      </Typography>
    </ListItem>
  );
};

export default OrderStockSearchItem;
