import React, { Fragment, useEffect, useState } from 'react';
import { Customer } from 'types/customer';
import CustomerSearch from '../customer-search/CustomerSearch';
import { Grid, MenuItem, TextField, IconButton, Collapse } from '@material-ui/core';
import IconSearch from '@material-ui/icons/Search';
import CustomerData from '../../CustomerData';
import Operations from 'components/operacao/Operations';
import PaymentMethod from 'pages/orders/new/payment-method/PaymentMethod';
import PaymentTerms from 'components/payment-terms/PaymentTerms';
import { useOrder } from '../hooks/useOrder';

interface OrderBasicProps {
  idOperacao: any;
  idFormaPagamento: any;
  idCondicaoPagamento: any;
  idRateio: any;
  desconto: any;
  observacao: any;
  items: any;
  handleOrderChange(key: string, value: any): void;
  handleSetCustomer(customer: Customer): void;
}

const OrderBasic: React.FC<OrderBasicProps> = ({
  idOperacao,
  idFormaPagamento,
  idCondicaoPagamento,
  idRateio,
  desconto,
  observacao,
  items,
  handleOrderChange,
  handleSetCustomer,
}) => {
  const [dialogCustomerSearch, setDialogCustomerSearch] = useState(false);
  const { order } = useOrder();

  useEffect(() => {
    if (!order?.customer) {
      setDialogCustomerSearch(state => !state);
    }
  }, [order?.customer]);

  function handleFocus() {
    setDialogCustomerSearch(!dialogCustomerSearch);
  }

  return (
    <Fragment>
      {dialogCustomerSearch && <CustomerSearch handleSetCustomer={handleSetCustomer} handleClose={handleFocus} />}
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <TextField
              required
              disabled={items.length > 0}
              fullWidth
              label="Cliente"
              variant="standard"
              placeholder="Informe o cliente"
              value={order?.customer?.razao ?? ''}
              onChange={event => (items.length === 0 ? handleOrderChange('cliente', event.target.value) : undefined)}
              margin="normal"
              onFocus={handleFocus}
              InputProps={{
                endAdornment: (
                  <IconButton disabled={items.length > 0} onClick={handleFocus}>
                    <IconSearch />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <div>
            {order?.customer && (
              <CustomerData
                customerId={order.customer.idCliente}
                companyName={order.customer.fantasia}
                documentNumber={order.customer.cnpj}
                street={order.customer.endereco}
                city={order.customer.cidade}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid item xl={4} md={6} lg={5} xs={12}>
            <Operations
              disabled={items.length > 0}
              change={event => (items.length === 0 ? handleOrderChange('idOperacao', event.target.value) : undefined)}
              idOperacao={idOperacao}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xl={4} md={6} lg={5} xs={12}>
            <TextField
              required
              disabled={items.length > 0}
              select
              variant="standard"
              label="Rateio"
              onChange={event => (items.length === 0 ? handleOrderChange('idRateio', event.target.value) : () => {})}
              value={idRateio}
              fullWidth
              margin="normal"
            >
              <MenuItem value="4">4 - BRASIL</MenuItem>
              <MenuItem value="PD">PD - BRADESCO</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Collapse in={idOperacao === '001'}>
            <Grid item xs={12}>
              <Grid item xl={4} md={6} lg={5} xs={12}>
                <PaymentMethod
                  disabled={items.length > 0}
                  change={event =>
                    items.length === 0 ? handleOrderChange('idFormaPagamento', event.target.value) : () => {}
                  }
                  idFormaPagamento={idFormaPagamento}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xl={4} md={6} lg={5} xs={12}>
                <PaymentTerms
                  disabled={items.length > 0 || (idFormaPagamento === '002' && idCondicaoPagamento === '0044')}
                  change={event =>
                    items.length === 0 || (idFormaPagamento !== '002' && idCondicaoPagamento !== '0044')
                      ? handleOrderChange('idCondicaoPagamento', event.target.value)
                      : () => {}
                  }
                  idCondicaoPagamento={idCondicaoPagamento}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xl={4} md={6} lg={5} xs={12}>
                <TextField
                  disabled={items.length > 0}
                  autoComplete={'off'}
                  variant="standard"
                  label="% Desconto"
                  name="desconto"
                  margin="normal"
                  value={desconto}
                  type={'number'}
                  onChange={event =>
                    items.length === 0 ? handleOrderChange('desconto', event.target.value) : undefined
                  }
                  placeholder="Informe o desconto"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <TextField
              label="Observação"
              multiline
              minRows={4}
              margin="normal"
              variant="standard"
              value={observacao}
              onChange={event => handleOrderChange('observacao', event.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OrderBasic;
