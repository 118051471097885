import React from 'react';
import { ListItem, makeStyles, Typography } from '@material-ui/core';
import { moneyFormat } from 'helpers/NumberFormat';

const styles = makeStyles({
  mb0: {
    marginBottom: 0,
  },
  productName: {
    marginBottom: 10,
  },
  listItem: {
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  star: {
    color: '#ffc107',
  },
  chipColor: {
    backgroundColor: '#17a2b8',
  },
});

interface ProductListPricesProps {
  productPrice: any;
  handleProductSelectToOrder(product: any): void;
}

const ProductPriceItem: React.FC<ProductListPricesProps> = ({ handleProductSelectToOrder, productPrice }) => {
  const classes = styles();

  return (
    <ListItem
      dense
      classes={{ root: classes.listItem }}
      onClick={() => handleProductSelectToOrder(productPrice)}
      button
    >
      <div>
        <Typography variant="caption">Tabela {productPrice.tablePriceId}</Typography>
        <Typography className={classes.productName} variant="h6">
          {productPrice.productId} - {productPrice.description}
        </Typography>
        <Typography>
          Preço: <strong>{moneyFormat(productPrice.price)}</strong>
        </Typography>
        <Typography className={classes.mb0}>
          Embalagem: {productPrice.secondUnit}/{productPrice.fator}
        </Typography>
      </div>
    </ListItem>
  );
};

export default ProductPriceItem;
