import React from 'react';
import { FormControlLabel, IconButton, Switch, Typography } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useOrder } from '../hooks/useOrder';
import { useAddProduct } from './hooks/useAddProduct';
import { useSelector } from 'store/selector';

interface AddProductActionProps {
  showStock: boolean;
  handleChange(): void;
  handleSubmit(): Promise<void>;
}

const AddProductAction: React.FC<AddProductActionProps> = ({ showStock, handleChange, handleSubmit }) => {
  const { saving, productToAdd, productToEdit } = useOrder();
  const { handleReset, quantity } = useAddProduct();
  const stock = useSelector(state => state.stock);

  function handleClick() {
    handleSubmit().then(handleReset);
  }

  return (
    <>
      {stock.products.length > 0 && !productToAdd && !quantity && (
        <FormControlLabel
          control={<Switch color="secondary" value={showStock} checked={showStock} onChange={handleChange} />}
          label={
            <Typography style={{ color: '#fff' }} variant="caption">
              Estoque
            </Typography>
          }
        />
      )}
      {(productToAdd ?? productToEdit) && (
        <IconButton color="inherit" disabled={saving} onClick={handleClick} type="button">
          <Done />
        </IconButton>
      )}
    </>
  );
};

export default AddProductAction;
