import { IconButton, Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface OrderActionButtonProps {
  icon: ReactNode;
  title: string;
  action(): void;
}

const OrderActionButton: React.FC<OrderActionButtonProps> = ({ action, icon, title }) => {
  return (
    <Tooltip title={title}>
      <IconButton color="inherit" onClick={action}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default OrderActionButton;
