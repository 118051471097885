import React, { useMemo } from 'react';
import { ListItem, makeStyles, Typography } from '@material-ui/core';
import { Order } from 'types/Order';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import OrderListItemInfo from './OrderListItemInfo';
import { useNavigate } from 'react-router-dom';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    minHeight: 175,
    paddingTop: 15,
  },
  listItemRazao: {
    marginBottom: 10,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
  },
  label: {
    width: 80,
    display: 'inline-flex',
    gap: '5px',
  },
  label2: {
    display: 'flex',
  },
}));

interface OrderListItemProps {
  order: Order;
}

const OrderListItem: React.FC<OrderListItemProps> = ({ order }) => {
  const classes = styles();
  const navigate = useNavigate();

  const formattedInvoiceValue = useMemo(() => moneyFormat(order.valor), [order]);
  const formattedDebitValue = useMemo(() => moneyFormat(order.vrComissao), [order]);

  return (
    <ListItem
      onClick={() => navigate(`/orders/view/${order.idEmpresa}/${order.idPedido}`)}
      button
      className={classes.listItem}
    >
      <OrderListItemInfo order={order} />

      <Typography className={classes.listItemRazao} variant="h6">
        {order.idCliente} - {order.razao}
      </Typography>
      <Typography className={classes.label2} variant="caption">
        <span className={classes.label}> Tipo:</span>
        {order.tipo}
      </Typography>
      <Typography className={classes.label2} variant="caption">
        <span className={classes.label}> Emissão:</span>
        {order.dtEmissao}
      </Typography>
      {order.vrComissao > 0 && (
        <Typography variant="caption">
          <span className={classes.label}>Comissão:</span>
          {formattedDebitValue} ({percentFormat(order.comissao)})
        </Typography>
      )}
      <Typography>
        <span className={classes.label}>Valor:</span>
        <strong>{formattedInvoiceValue}</strong>
      </Typography>
    </ListItem>
  );
};

export default OrderListItem;
