import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import SimpleLoading from 'components/loading/SimpleLoading';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/NumberFormat';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import { CustomerEn } from 'types/customer';
import { Company } from 'types/Company';
import { OrderToPrint } from 'types/OrderToPrint';

const styles = makeStyles({
  container: {
    minWidth: 680,
    font: "12px 'trebuchet ms', sans-serif",
  },
  m0: {
    margin: 0,
  },
  image: {
    width: 150,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0',
  },
  title: {
    fontSize: 18,
    margin: '12px 0',
    textAlign: 'center',
  },
  tableHeader: {
    padding: 10,
    backgroundColor: '#eee',
    border: '1px solid #000',
  },
  tableRow: {
    padding: 5,
    border: '1px solid #000',
  },
});

type OrderViewParams = {
  companyId: string;
  orderId: string;
};

const OrderPrint: React.FC = () => {
  const classes = styles();
  const [loadingOrder, setLoadingOrder] = useState(true);
  const { orderId, companyId } = useParams<OrderViewParams>();
  const [order, setOrder] = useState<OrderToPrint | null>(null);
  const [customer, setCustomer] = useState<CustomerEn | null>(null);
  const [company, setCompany] = useState<Company | null>(null);

  useEffect(() => {
    if (!order?.idCliente) {
      return;
    }

    api
      .get('cliente/load', { params: { id: order.idCliente } })
      .then(response => {
        setCustomer(response.data.customer);
        setLoadingOrder(false);
      })
      .catch(err => console.error(err));
  }, [order]);

  useEffect(() => {
    api
      .get('/vendas/loadPedido', { params: { idPedido: orderId, idEmpresa: companyId } })
      .then(response => {
        setOrder(response.data.pedido);
        setLoadingOrder(false);
      })
      .catch(err => console.error(err));
  }, [orderId, companyId]);

  useEffect(() => {
    api
      .get('empresa/load', { params: { id: companyId } })
      .then(response => {
        setCompany(response.data.company);
        setLoadingOrder(false);
      })
      .catch(err => console.error(err));
  }, [companyId]);

  useEffect(() => {
    if (!order) {
      return;
    }

    if (!customer) {
      return;
    }
    if (!company) {
      return;
    }
    window.print();
  }, [order, customer, company]);

  return (
    <Fragment>
      <Appbar
        title={'Vendas'}
        ActionsComponent={
          <Fragment>
            <IconButton onClick={window.print} color={'inherit'}>
              <PrintIcon />
            </IconButton>
            <IconButton component={Link} to={'/new-order'} color={'inherit'}>
              <AddIcon />
            </IconButton>
          </Fragment>
        }
      />

      <div className={classes.container}>
        {loadingOrder && <SimpleLoading />}
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>
                <img
                  alt={'Logo São Francisco'}
                  className={classes.image}
                  src={process.env.REACT_APP_MEDIAURL + 'images/logosf.png'}
                />
              </td>
              <td style={{ textAlign: 'right' }}>
                <p className={classes.m0}>{company?.fantasy}</p>
                <p className={classes.m0}>
                  {company?.address}, {company?.number}
                </p>
                <p className={classes.m0}>
                  {company?.cep} - {company?.state}
                </p>
                <p className={classes.m0}>{company?.document}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className={classes.title}>
          <strong>
            Pedido {order?.idPedido} - {order?.dtEmissao}
          </strong>
        </p>
        <p style={{ paddingBottom: 7, marginBottom: 0, marginTop: 20 }}>
          <strong>Cliente ({order?.idCliente})</strong>
        </p>
        <table style={{ border: '1px solid #000' }} className={classes.table}>
          <tbody>
            <tr>
              <td style={{ width: 80, padding: '5px 0 0 5px' }}>
                <strong>Razão social</strong>
              </td>
              <td>{customer?.name}</td>
            </tr>
            <tr>
              <td style={{ width: 80, paddingLeft: 5 }}>
                <strong>Endereço</strong>
              </td>
              <td>
                {customer?.address}, {customer?.addressNumber} / {customer?.city} - {customer?.state}
              </td>
            </tr>
            <tr>
              <td style={{ width: 80, paddingLeft: 5 }}>
                <strong>CNPJ</strong>
              </td>
              <td>{customer?.cnpj}</td>
            </tr>
            <tr>
              <td style={{ width: 80, padding: '0 0 5px 5px' }}>
                <strong>Email</strong>
              </td>
              <td>{customer?.mail ? customer?.mail : 'Não informado'}</td>
            </tr>
          </tbody>
        </table>
        <p style={{ paddingBottom: 7, marginTop: 20, marginBottom: 0 }}>
          <strong>Condições</strong>
        </p>
        <table style={{ paddingBottom: 7, border: '1px solid #000' }} className={classes.table}>
          <tbody>
            <tr>
              <td style={{ width: 150, padding: '5px 0 0 5px' }}>
                <strong>Tipo:</strong>
              </td>
              <td>{order?.operacao}</td>
            </tr>
            <tr>
              <td style={{ width: 150, paddingLeft: 5 }}>
                <strong>Série:</strong>
              </td>
              <td>{order?.idRateio}</td>
            </tr>
            {order?.desconto && (
              <tr>
                <td style={{ width: 150, paddingLeft: 5 }}>
                  <strong>Desconto:</strong>
                </td>
                <td>{percentFormat(order?.desconto)}</td>
              </tr>
            )}
            {order?.descricaoCondicaoPagamento && (
              <tr>
                <td style={{ width: 150, paddingLeft: 5 }}>
                  <strong>Condição de pagamento:</strong>
                </td>
                <td>{order.descricaoCondicaoPagamento}</td>
              </tr>
            )}
            {order?.invoice.length ? (
              <tr>
                <td style={{ width: 150, padding: '0 0 5px 5px' }}>
                  <strong>Fatura:</strong>
                </td>
                <td>
                  {order?.invoice.map((item, index) => {
                    return (
                      <p key={index} className={classes.m0}>
                        {item.paymentDay} - <strong>{moneyFormat(item.value)}</strong> - {item.description}
                      </p>
                    );
                  })}
                </td>
              </tr>
            ) : (
              <td></td>
            )}
          </tbody>
        </table>
        <p style={{ paddingBottom: 7, marginTop: 20, marginBottom: 0 }}>
          <strong>Itens do Pedido</strong>
        </p>
        <table className={classes.table} style={{ border: '1px solid #000' }}>
          <tbody>
            <tr>
              <td className={classes.tableHeader}>Código</td>
              <td className={classes.tableHeader}>Descrição</td>
              <td className={classes.tableHeader}>Quantidade</td>
              <td className={classes.tableHeader}>Unidade</td>
              <td className={classes.tableHeader}>Valor</td>
              <td className={classes.tableHeader}>Desconto</td>
              <td className={classes.tableHeader}>Subtotal</td>
            </tr>
            {order?.items.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={classes.tableRow}>{item.productId}</td>
                  <td className={classes.tableRow}>{item.description}</td>
                  <td style={{ textAlign: 'right' }} className={classes.tableRow}>
                    {numberFormat(item.quantity)}
                  </td>
                  <td style={{ textAlign: 'center' }} className={classes.tableRow}>
                    {item.unit}
                  </td>
                  <td style={{ textAlign: 'right' }} className={classes.tableRow}>
                    {moneyFormat(item.price)}
                  </td>
                  <td style={{ textAlign: 'right' }} className={classes.tableRow}>
                    {percentFormat(item.discount)}
                  </td>
                  <td style={{ textAlign: 'right' }} className={classes.tableRow}>
                    {moneyFormat(item.total)}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td
                style={{
                  marginTop: 10,
                  backgroundColor: '#eee',
                  textAlign: 'right',
                  border: '1px solid #000',
                  padding: 5,
                }}
                colSpan={6}
              >
                <p className={classes.m0}>Total produtos</p>
                <p className={classes.m0}>Desconto</p>
                <p className={classes.m0}>ICMS ST</p>
                <p className={classes.m0}>Total</p>
              </td>
              <td style={{ textAlign: 'right', padding: 5 }}>
                <p className={classes.m0}>{moneyFormat(order?.productTotal)}</p>
                <p className={classes.m0}>{moneyFormat(order?.discount)}</p>
                <p className={classes.m0}>{moneyFormat(order?.vrIcmsSt)}</p>
                <p className={classes.m0}>{moneyFormat(order?.finalTotal)}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default OrderPrint;
