import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import history from 'services/history';
import { useListStyle } from 'providers/listStyle';
import AppsIcon from '@material-ui/icons/Apps';
import DehazeIcon from '@material-ui/icons/Dehaze';

interface useStylesProps {
  gutterBottom: boolean;
}

const useStyles = makeStyles(theme => ({
  header: ({ gutterBottom }: useStylesProps) => ({
    marginBottom: gutterBottom ? 10 : 0,
    display: 'flex',
    alignItems: 'center',
    '@media print': {
      display: 'none',
    },
  }),
  backButton: {
    marginRight: 15,
  },
  alignButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'end',
  },
  actions: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface PageHeaderProps {
  title: string;
  description?: string;
  gutterBottom?: boolean;
  backUrl?: string;
  buttonDisplay?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description, gutterBottom = true, backUrl, buttonDisplay }) => {
  const classes = useStyles({ gutterBottom });
  const context = useListStyle();

  return (
    <Grid className={classes.header} item>
      {backUrl && (
        <IconButton className={classes.backButton} size="small" onClick={() => history.push(backUrl)}>
          <ArrowBack />
        </IconButton>
      )}
      <div className={classes.alignButtons}>
        <div>
          <Typography variant="h6">{title}</Typography>
          {description && (
            <Typography color="textSecondary" variant="body2">
              {description}
            </Typography>
          )}
        </div>
        <div className={classes.actions}>
          <IconButton
            color={context.listStyle === 'grid' ? 'primary' : undefined}
            onClick={() => context.setListStyle('grid')}
          >
            <AppsIcon />
          </IconButton>
          <IconButton
            color={context.listStyle === 'detail' ? 'primary' : undefined}
            onClick={() => context.setListStyle('detail')}
          >
            <DehazeIcon />
          </IconButton>
        </div>
      </div>
    </Grid>
  );
};

export default PageHeader;
