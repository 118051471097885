import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = {
  paper: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 650,
  },
};

const TableNoData = props => {
  const { classes } = props;
  return (
    <div className={classes.paper}>
      <Grid item xs={12}>
        <Typography align={'center'}>Nenhum registro foi carregado</Typography>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(TableNoData);
