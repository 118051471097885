import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { api } from 'services/api';

interface OperationsProps {
  change?: any;
  idOperacao: string;
  disabled: boolean;
}

const Operations: React.FC<OperationsProps> = ({ change, idOperacao, disabled }) => {
  const [operations, setOperations] = useState<any[]>([]);

  useEffect(() => {
    api
      .get('/operacao')
      .then(response => {
        setOperations(response.data.operacao);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <TextField
      variant="standard"
      select
      required
      fullWidth
      label="Tipo"
      value={!operations.length ? '' : idOperacao}
      onChange={change}
      margin="normal"
      name="idOperacao"
      disabled={disabled}
    >
      {operations.map(item => (
        <MenuItem key={item.id} value={item.id}>
          <span>
            {item.id} - {item.descricao}
          </span>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Operations;
