import React, { useMemo } from 'react';
import List from '@material-ui/core/List';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Pagination from 'components/pagination/Pagination';
import CommissionListItem from './CommissionListItem';
import { usePagination } from 'providers/pagination';
import { Commission } from 'types/Commission';
import { useListStyle } from 'providers/listStyle';

type Styles = {
  listStyle: string;
};

const styles = makeStyles<Theme, Styles>(theme => ({
  list: props => ({
    display: 'grid',
    gridTemplateColumns: props.listStyle === 'grid' ? 'repeat(auto-fill, minmax(400px, 1fr))' : '1fr',
    gridAutoRows: 'min-content',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: props.listStyle === 'grid' ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr',
    },
  }),
}));

interface CommissionListProps {
  commission: Commission[];
}

type PaginationValues = {
  start: number;
  end: number;
};

const CommissionList: React.FC<CommissionListProps> = ({ commission }) => {
  const { listStyle } = useListStyle();
  const classes = styles({ listStyle });
  const { rowsPerPage, page } = usePagination();

  const pagination = useMemo((): PaginationValues => {
    return {
      start: page * rowsPerPage,
      end: page * rowsPerPage + rowsPerPage,
    };
  }, [page, rowsPerPage]);

  return (
    <>
      <List className={classes.list} disablePadding>
        {commission.slice(pagination.start, pagination.end).map(commission => (
          <CommissionListItem commission={commission} key={commission.F13_ID} />
        ))}
      </List>
      <Pagination count={commission.length} />
    </>
  );
};

export default CommissionList;
