import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  modal: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1110,
    backgroundColor: 'rgba(255,255,255,1)',
  },
  loading: {
    top: '30%',
    transform: 'translate(-50%)',
    fontSize: 16,
    color: '#fff',
    zIndex: 1100,
    padding: 15,
    textAlign: 'center',
    position: 'inherit',
    left: '50%',
    width: 200,
  },
  body: {
    overflowY: 'hidden',
    paddingRight: 17,
  },
};

class SimpleLoading extends React.Component {
  state = {
    open: true,
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  componentWillUnmount() {
    const { classes } = this.props;

    document.body.classList.remove(classes.body);
  }

  render() {
    const { classes } = this.props;

    document.body.classList.add(classes.body);
    return (
      <div className={classes.modal}>
        <div className={classes.loading}>
          <CircularProgress color={'primary'} />
        </div>
      </div>
    );
  }
}

/*
const SimpleLoading = (props) => {
    const {classes} = props;
    const [open, handleOpen] = useState(true);
    return(
        <Modal
            onClose={handleOpen}
            open={open}
            classes={{root: classes.background}}
        >
            <div className={classes.loading}>
                <CircularProgress color={"primary"}/>
            </div>
        </Modal>
    )
}
*/

export default withStyles(styles)(SimpleLoading);
