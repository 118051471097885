const baseUrlApi = process.env.REACT_APP_BASEURL_API;

const timeout = 200000;

class ApiRequest {
  request;

  /**
   * Trata o código do erro.
   * @param statusCode
   * @returns {string}
   */
  handleError(statusCode, status) {
    let message = '';

    if (typeof status === 'undefined') status = '';
    if (typeof statusCode === 'undefined') statusCode = 500;

    if (statusCode === 0) message = 'Não possível conectar ao servidor';
    else if (statusCode === 401) {
      // localStorage.removeItem('token');
      // window.location.href = process.env.REACT_APP_BASEURL+'login';
      message = 'Acesso negado';
    } else if (statusCode === 500) message = 'Aconteceu um erro ao processar a requisição';
    else if (statusCode === 404) message = 'Endereço requisitado não encontrado';
    else message = 'Não foi possível completar a requisição';

    return message + ' - ' + statusCode + ' ' + status;
  }

  async timeout(ms, promise) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        reject(new Error('Não foi possível concluir a requisição. Timeout'));
      }, ms);
      promise.then(resolve, reject);
    });
  }

  /**
   * Make a request using Fetch
   * @param uri
   * @param param
   * @returns {Promise<void>}
   */
  async doGet(uri, param = {}) {
    let result = {};
    let paramEncoded = '';

    if (Array.isArray(param)) {
      // param = [...param, {name: 'token', value: localStorage.getItem('token')}];
      paramEncoded =
        '?' + param.map(item => encodeURIComponent(item.name) + '=' + encodeURIComponent(item.value)).join('&');
    } else if (typeof param === 'object') {
      // param.token = localStorage.getItem('token');
      paramEncoded =
        '?' +
        Object.keys(param)
          .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(param[key]))
          .join('&');
    }

    const init = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    };

    await this.timeout(
      timeout,
      fetch(baseUrlApi + uri + paramEncoded, init)
        .then(async response => {
          if (response.ok) {
            result = await response.json();
          } else {
            result = {
              type: 'ERROR',
              message: this.handleError(response.status, response.statusText),
            };
          }
        })
        .catch(response => {
          result = {
            type: 'ERROR',
            message: this.handleError(response.status, response.statusText),
          };
        })
    ).catch(reject => {
      result = {
        type: 'ERROR',
        message: reject.message,
      };
    });

    return result;
  }

  /**
   * Make a post request using Fetch
   * @param uri
   * @param param
   * @returns {Promise<void>}
   */
  async doPost(uri, param = {}) {
    let result = {};
    const formData = new FormData();

    if (Array.isArray(param)) {
      // param = [...param, {name: 'token', value: localStorage.getItem('token')}];
      param.forEach(item => formData.append(item.name, item.value));
    } else if (typeof param === 'object') {
      // param.token = localStorage.getItem('token');
      Object.keys(param).forEach(key => formData.append(key, param[key]));
    }

    await this.timeout(
      timeout,
      fetch(baseUrlApi + uri, {
        method: 'post',
        body: formData,
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
        .then(async response => {
          if (response.ok) {
            result = await response.json();
          } else {
            result = {
              type: 'ERROR',
              message: this.handleError(response.status, response.statusText),
            };
          }
        })
        .catch(response => {
          result = {
            type: 'ERROR',
            message: this.handleError(response.status, response.statusText),
          };
        })
    ).catch(reject => {
      result = {
        type: 'ERROR',
        message: reject.message,
      };
    });

    return result;
  }
}

export default ApiRequest;
