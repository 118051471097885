import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const MyAccountBasic = props => {
  const { handleChange, username, email, handleSubmitUpdateEmail } = props;

  return (
    <form onSubmit={handleSubmitUpdateEmail}>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant={'h6'}>Minha conta</Typography>
          <Typography>Nesta seção você poderá apenas alterar seu endereço de email.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={6} lg={4} xl={3}>
            <TextField
              disabled
              margin={'normal'}
              variant={'standard'}
              value={username}
              onChange={handleChange}
              name={'username'}
              label={'Nome de usuario'}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item md={6} lg={4} xl={3}>
            <TextField
              required
              margin={'normal'}
              variant={'standard'}
              value={email}
              type={'email'}
              onChange={handleChange}
              label={'E-mail'}
              name={'email'}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default MyAccountBasic;
