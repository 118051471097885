import React, { useState, FormEvent } from 'react';
import { makeStyles, Typography, Button, CircularProgress } from '@material-ui/core';
import LoginForm from './LoginForm';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from 'providers/auth';
import history from 'services/history';
import * as yup from 'yup';
import { useMessaging } from 'providers/messaging';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      backgroundColor: '#fff',
    },
  },
  content: {
    display: 'flex',
    padding: 30,
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    minWidth: 400,
    margin: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 300,
      padding: 10,
    },
  },
  header: {
    padding: '0 0 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h5': {
      marginTop: 20,
    },
  },
  actions: {
    display: 'flex',
    padding: '50px 0',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .forgot': {
      marginTop: 30,
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  logo: {
    width: 200,
  },
  logoWrapper: {
    width: 200,
    height: 120,
  },
}));

export type LoginValidation = {
  email?: string;
  password?: string;
};

const Login: React.FC = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<LoginValidation>({});
  const auth = useAuth();
  const messaging = useMessaging();

  function handleSubmit() {
    setLoading(true);

    auth
      .login(username, password)
      .then(() => {
        history.push('/');
      })
      .catch(err => {
        setLoading(false);

        console.log(err);

        if (err.response && err.response.status === 401) {
          setValidation({
            email: 'E-mail está correto?',
            password: 'Senha está correta?',
          });
          return;
        }

        if (err.response) {
          messaging.handleOpen('Não foi possível fazer login');
          console.log(err.response);
          return;
        }

        messaging.handleOpen('Não foi possível conectar ao servidor');
      });
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    const schema = yup.object().shape({
      password: yup.string().required('A senha é obrigatória'),
      username: yup.string().required('Nome de usuário é obrigatório'),
    });

    schema
      .validate({ username, password })
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        setValidation({
          [err.path as string]: err.message,
        });
      });
  }

  if (auth.checkAuth()) {
    return <Navigate to="/" />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        )}
        <div className={classes.header}>
          <div className={classes.logoWrapper}>
            <a href={process.env.PUBLIC_URL}>
              <img
                className={classes.logo}
                src={process.env.REACT_APP_MEDIAURL + 'images/logosf.png'}
                alt="São Frnacisco utensílios de limpeza logo"
              />
            </a>
          </div>
          <Typography align="center" variant="h5">
            Login
          </Typography>
        </div>
        <form onSubmit={handleValidation}>
          <LoginForm
            username={username}
            password={password}
            setPassword={setPassword}
            setUsername={setUsername}
            validation={validation}
          />
        </form>
        <div className={classes.actions}>
          <Button
            onClick={() => handleValidation(undefined)}
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            disabled={loading}
          >
            Entrar
          </Button>
          <div className="forgot">
            <Link to="/forgot">Esqueci minha senha</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
