import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#dc3545',
    },
    secondary: {
      main: '#FFC107',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      '"Montserrat"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
    },
  },
});

export { theme };
