import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography } from '@material-ui/core';

const styles = {
  N: {
    backgroundColor: '#6bc720',
  },
  S: {
    backgroundColor: '#ff8100',
  },
  containerFields: {
    marginTop: 20,
  },
  chipRoot: {
    marginBottom: 10,
  },
  margin: {
    marginTop: 15,
  },
};

class ClientBasic extends React.Component {
  render() {
    const { customer, classes } = this.props;

    return (
      <Fragment>
        <Grid>
          <div className={classes.margin}>
            <Typography>
              <strong>Dados</strong>
            </Typography>
          </div>
          <Grid container item spacing={4}>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="Código"
                variant="standard"
                value={customer.id}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="CNPJ"
                variant="standard"
                value={customer.cnpj}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.margin}>
            <Typography>
              <strong>Endereço</strong>
            </Typography>
          </div>
          <Grid container item spacing={4}>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="Endereço"
                variant="standard"
                value={customer.address}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="Bairro"
                variant="standard"
                value={customer.neighborHood}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="Cidade"
                variant="standard"
                value={customer.city}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="CEP"
                variant="standard"
                value={customer.zipcode}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>

          <div className={classes.margin}>
            <Typography>
              <strong>Contato</strong>
            </Typography>
          </div>
          <Grid container item spacing={4}>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="Telefone"
                variant="standard"
                value={customer.phone}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3} md={5} xl={2}>
              <TextField
                readOnly
                autoComplete={'off'}
                label="E-mail"
                variant="standard"
                value={customer.mail}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3} md={5} xl={2}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="Tabela de Preço"
              variant="standard"
              value={customer.tablePrice + ' - ' + customer.tablePriceName}
              margin="normal"
              fullWidth
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ClientBasic);
