import React from 'react';
import { numberFormat } from 'helpers/NumberFormat';
import { ListItem, Theme, makeStyles, Typography, alpha } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useOrder } from '../hooks/useOrder';
import { OrderProduct as OrderProductType } from 'types/product';

interface StyleProps {
  isSelected: boolean;
}

const styles = makeStyles<Theme, StyleProps>(theme => ({
  chipPrice: {
    marginTop: 10,
  },
  listItem: props => ({
    display: 'block',
    backgroundColor: props.isSelected ? alpha(theme.palette.primary.main, 0.1) : '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer',
    border: props.isSelected ? `2px dashed ${theme.palette.primary.main}` : '2px solid #eee',
  }),
  productName: {
    marginBottom: 7,
  },
  label: {
    display: 'inline-block',
    width: '120px',
  },
  listItemWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 4,
  },
  checkCircleIcon: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '50%',
  },
}));

interface OrderProductProps {
  product: OrderProductType;
}

const OrderProduct: React.FC<OrderProductProps> = ({ product }) => {
  const { handleSelectProducts, selectedProducts } = useOrder();
  const classes = styles({ isSelected: selectedProducts.some(item => item.productId === product.productId) });

  return (
    <ListItem onClick={() => handleSelectProducts(product)} className={classes.listItem} key={product.productId}>
      {selectedProducts.some(item => item.productId === product.productId) && (
        <div className={classes.listItemWrapper}>
          <CheckCircleIcon className={classes.checkCircleIcon} color="primary" />
        </div>
      )}
      <Typography className={classes.productName} variant="h6">
        {product.productId} - {product.description}
      </Typography>
      <Typography>
        <span className={classes.label}>Quantidade: </span>
        {product.quantity}
        {product.unit} ({numberFormat(product.secondQuantity, 2)}
        {product.secondUnit})
      </Typography>

      <Typography>
        <span className={classes.label}>Desconto: </span>
        {product.formattedVlDiscount} ({product.formattedPercentDiscount})
      </Typography>

      <Typography>
        <span className={classes.label}>Preço: </span>
        {product.formattedPrice}
      </Typography>

      <Typography>
        <span className={classes.label}>Comissão: </span>
        {product.formattedVlCommission} ({product.formattedPercentCommission})
      </Typography>

      <Typography>
        <span className={classes.label}>Total: </span>
        <strong>{product.formattedTotal}</strong>
      </Typography>
    </ListItem>
  );
};

export default OrderProduct;
