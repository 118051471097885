import React from 'react';
import { makeStyles } from '@material-ui/core';
import { OrderProduct as OrderProductType } from 'types/product';
import OrderNoProducts from '../new/products/OrderNoProducts';
import OrderProductsList from './OrderProductsList';

const styles = makeStyles(theme => ({
  ul: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gap: 10,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    },
  },
}));

interface OrderProductsProps {
  products: OrderProductType[];
}

const OrderProducts: React.FC<OrderProductsProps> = ({ products }) => {
  const classes = styles();

  return (
    <div>
      {products.length ? (
        <ul className={classes.ul}>
          {products.map(product => {
            return <OrderProductsList product={product} key={product.productId} />;
          })}
        </ul>
      ) : (
        <OrderNoProducts />
      )}
    </div>
  );
};

export default OrderProducts;
