import { makeStyles, TextField, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'store/selector';

const styles = makeStyles(theme => ({
  container: {
    padding: '10px 30px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  row: {
    marginTop: 20,
  },
  label: {
    width: 85,
    display: 'inline-block',
  },
  space: {
    marginTop: '10px',
  },
}));

interface WeeklyClosingFormProps {
  observation: string;
  setObservation: Dispatch<SetStateAction<string>>;
  formattedTotal: string;
  quantity: number;
}

const WeeklyClosingForm: React.FC<WeeklyClosingFormProps> = ({
  observation,
  setObservation,
  formattedTotal,
  quantity,
}) => {
  const classes = styles();
  const user = useSelector(state => state.user);

  return (
    <>
      <div>
        <Typography variant="h6">Olá {user?.name}.</Typography>
        <Typography>
          O fechamento trata-se do envio das informações da venda semanal. Isso é necessário para confirmarmos seus
          pedidos.
          <span className={classes.italic}> Antes de enviar verifique se as informações estão corretas</span>. Uma
          mensagem de e-mail será enviada aos responsáveis com cópia para você.
          <span className={classes.italic}> Apenas pedidos do tipo venda são considerados nesse fechamento</span>.
        </Typography>
      </div>
      <div className={classes.space}>
        <Typography variant="subtitle2">
          <span className={classes.label}>Total:</span> <strong>{formattedTotal}</strong>
        </Typography>
        <Typography variant="subtitle2">
          <span className={classes.label}>Pedidos:</span> <strong>{quantity}</strong>
        </Typography>
        <TextField
          placeholder="Digite aqui sua observação"
          required
          helperText="Você deve fazer uma observação"
          autoFocus
          label="Observação"
          multiline
          rows="4"
          margin="normal"
          variant="standard"
          value={observation}
          onChange={e => setObservation(e.target.value)}
          fullWidth
        />
      </div>
    </>
  );
};
export default WeeklyClosingForm;
