import axios, { CancelTokenSource, AxiosError } from 'axios';
import history from './history';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access-token');
    if (config.headers) if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (err: AxiosError) => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  config => {
    return config;
  },
  err => {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem('access-token');
      history.push('/login');
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export function getCancelTokenSource(): CancelTokenSource {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return source;
}

export { api };
