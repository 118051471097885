import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api } from 'services/api';
import { ProductDiscount } from 'types/productDiscount';
import { useOrder } from '../../hooks/useOrder';

interface UseFetchPrices {
  loading: boolean;
  price: null | number;
  discounts: ProductDiscount[];
  setPrice: Dispatch<SetStateAction<null | number>>;
  setDiscounts: Dispatch<SetStateAction<ProductDiscount[]>>;
}

export function useFetchPrices(): UseFetchPrices {
  const { productToAdd, productToEdit, order } = useOrder();
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState<null | number>(null);
  const [discounts, setDiscounts] = useState<any[]>([]);

  useEffect(() => {
    const product = productToAdd ?? productToEdit;

    if (!product) {
      return;
    }

    setLoading(true);

    api
      .get('/vendas/getPrice', {
        params: {
          idProduto: product.productId,
          idCliente: order.customer?.idCliente,
          serie: order.idRateio,
        },
      })
      .then(response => {
        setPrice(response.data.price);
        setDiscounts(response.data.discounts);
      })
      .catch(err =>
        console.error(err.response ? err.response.data.message : 'Não foi possível recuperar o preço do produto')
      )
      .finally(() => setLoading(false));
  }, [productToAdd, productToEdit, order.customer, order.idRateio]);

  return {
    loading,
    price,
    discounts,
    setPrice,
    setDiscounts,
  };
}
