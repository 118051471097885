import React from 'react';
import OrderStockItem from './OrderStockItem';
import { List, Typography } from '@material-ui/core';
import { useSelector } from 'store/selector';
import { useOrder } from '../../hooks/useOrder';

const OrderStockList: React.FC = () => {
  const stock = useSelector(state => state.stock);
  const { order } = useOrder();

  return (
    <>
      <Typography variant="caption">Escolha um produto abaixo</Typography>
      <List>
        {stock.products.map(item => (
          <OrderStockItem orderItems={order.items} stock={item} key={item.productId} />
        ))}
      </List>
    </>
  );
};

export default OrderStockList;
