import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';

interface OrdersFilterActionProps {
  handleSubmit(): void;
  setInitialStateFilterValue(): void;
}

const OrdersFilterAction: React.FC<OrdersFilterActionProps> = ({ handleSubmit, setInitialStateFilterValue }) => {
  return (
    <div>
      <Tooltip title="Limpar">
        <IconButton onClick={setInitialStateFilterValue} color="inherit">
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Pesquisa'}>
        <IconButton onClick={handleSubmit} color="inherit">
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
export default OrdersFilterAction;
