import React, { useCallback, useEffect, useState } from 'react';
import TableNoData from 'components/template/table/TableNoData';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import PageHeader from 'components/page-header/PageHeader';
import PaginationProvider from 'providers/pagination';
import { makeStyles } from '@material-ui/core';
import TableLoading from 'components/loading/TableLoading';
import InvoiceList from './list/InvoiceList';
import { addDays, subDays } from 'date-fns';
import { dateFormat } from 'helpers/DateFormat';
import InvoicesAction from './InvoicesAction';
import InvoicesFilterList from './InvoicesFilterList';
import InvoicesFilter from './filter/InvoicesFilter';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type FilterProps = {
  name: string;
  value: string;
  text: string;
};

const initialDate = subDays(new Date(), 90);
const finalDate = addDays(new Date(), 60);

const initialFilterValue: FilterProps[] = [
  { name: 'dataInicial', value: initialDate.toISOString(), text: dateFormat(initialDate) },
  { name: 'dataFinal', value: finalDate.toISOString(), text: dateFormat(finalDate) },
  { name: 'situacao', value: 'T', text: '' },
  { name: 'search', value: '', text: '' },
  { name: 'empresa', value: '', text: '' },
];

const invoiceStatus = {
  A: 'Aberto',
  V: 'Vencido',
  P: 'Liquidado',
};

const Invoices: React.FC = () => {
  const classes = styles();
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filters, setFilters] = useState<FilterProps[]>(initialFilterValue);

  const fetchInvoices = useCallback(async (filters?: FilterProps[]) => {
    setLoading(true);

    const params = new URLSearchParams();

    filters?.forEach(item => {
      params.append(item.name, item.value);
    });

    api
      .get('/titulos/getList', { params })
      .then(response => {
        setInvoices(response.data.data ?? []);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('invoiceFilters', JSON.stringify(filters));
  }, [filters, fetchInvoices]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    const _filter = localStorage.getItem('invoiceFilters');

    if (!_filter) {
      return;
    }

    setFilters(JSON.parse(_filter));
  }, []);

  function handleSubmit() {
    fetchInvoices(filters);
    setOpenSearch(false);
  }

  function setInitialStateFilterValue() {
    setFilters(initialFilterValue);
  }

  function handleFilterChange(name: string, value: any) {
    const newfilter = filters.map(item => {
      if (item.name !== name) {
        return item;
      }

      if (name === 'dataInicial' || name === 'dataFinal') {
        item.text = dateFormat(value);
        item.value = value ? value.toISOString() : '';
        return item;
      }

      if (name === 'situacao') {
        item.text = invoiceStatus[value];
        item.value = value;
        return item;
      }

      item.value = value;
      item.text = value;
      return item;
    });

    setFilters(newfilter);
  }

  async function handleClearFilter(name: string) {
    const _filters = filters.map(item => {
      if (name !== item.name) {
        return item;
      }

      return {
        name,
        value: name === 'situacao' ? 'T' : '',
        text: '',
      };
    });

    setFilters(_filters);

    fetchInvoices(_filters);
  }

  return (
    <>
      <Appbar title="Títulos" ActionsComponent={<InvoicesAction handleOpenSearch={() => setOpenSearch(true)} />} />
      {openSearch && (
        <InvoicesFilter
          handleFilterChange={handleFilterChange}
          filters={filters}
          handleSubmit={handleSubmit}
          onExited={() => setOpenSearch(false)}
          setInitialStateFilterValue={setInitialStateFilterValue}
        />
      )}
      <div className={classes.container}>
        <PageHeader title="Títulos" description="Gestão dos títulos" />

        <InvoicesFilterList filters={filters} handleClearFilter={handleClearFilter} />

        <PaginationProvider>
          {loading ? <TableLoading /> : !invoices.length ? <TableNoData /> : <InvoiceList invoices={invoices} />}
        </PaginationProvider>
      </div>
    </>
  );
};

export default Invoices;
