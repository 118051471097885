import React, { useCallback, useContext, useState, useEffect, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from 'store/modules/user/actions';
import { api } from 'services/api';
import { jwtDecode } from 'helpers/jwtDecode';

interface AuthContextData {
  login(username: string, password: string): Promise<boolean>;
  logout(): Promise<boolean>;
  checkAuth(): boolean;
  isAuthenticated: boolean;
  loggingOff: boolean;
}

const AuthContext = React.createContext({} as AuthContextData);

interface AuthProviderProps {
  children: ReactElement;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const history = useNavigate();
  const [loggingOff, setLoggingOff] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('access-token');

    if (!token) {
      return;
    }

    const payload = jwtDecode(token);

    if (!payload) {
      return;
    }

    dispatch(
      setUser({
        email: payload.data.email,
        id: payload.data.id,
        name: payload.data.name,
        username: payload.data.username,
      })
    );
    setIsAuthenticated(true);
  }, [dispatch]);

  const login = useCallback(
    async (username: string, password: string): Promise<boolean> => {
      const formdata = new FormData();

      formdata.append('username', username);
      formdata.append('pass', password);

      return new Promise((resolve, reject) => {
        api
          .post('/login', formdata)
          .then(_response => {
            const response = _response.data.data;
            localStorage.setItem('access-token', response.token);
            dispatch(
              setUser({
                email: response.email,
                id: response.idRepresentante,
                name: response.name,
                username: response.username,
              })
            );
            setIsAuthenticated(true);
            resolve(true);
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 401) reject(err);
            } else reject(err);
          });
      });
    },
    [dispatch]
  );

  const logout = useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      setLoggingOff(true);
      api
        .post('/logout')
        .then(() => {
          localStorage.removeItem('access-token');
          setIsAuthenticated(false);
          dispatch(setUser(null));
          history('/login');
          setLoggingOff(false);
          resolve(true);
        })
        .catch(err => {
          setLoggingOff(false);
          reject(new Error(err));
        });
    });
  }, [history, dispatch]);

  const checkAuth = useCallback((): boolean => {
    const token = localStorage.getItem('access-token');
    return !!token;
  }, []);

  return (
    <AuthContext.Provider value={{ login, logout, checkAuth, isAuthenticated, loggingOff }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export default AuthProvider;
