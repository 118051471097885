import React, { useMemo } from 'react';
import { makeStyles, List, ListItem, Typography } from '@material-ui/core';
import { moneyFormat } from 'helpers/NumberFormat';
import OrderDiscountItem from './OrderDiscountItem';
import { useOrder } from '../../hooks/useOrder';
import { useAddProduct } from '../hooks/useAddProduct';
import { Product } from 'types/product';

const styles = makeStyles({
  listItem: {
    padding: '5px 0',
    display: 'block',
    '& p': {
      display: 'grid',
      gridTemplateColumns: '110px 1fr',
    },
  },
});

const OrderListDiscounts: React.FC = () => {
  const classes = styles();
  const { productToAdd, productToEdit } = useOrder();
  const { price, discounts } = useAddProduct();

  const product = useMemo((): Product | null => {
    if (productToAdd) {
      return productToAdd;
    }

    if (productToEdit) {
      return { ...productToEdit };
    }

    return null;
  }, [productToAdd, productToEdit]);

  return (
    <List>
      <ListItem className={classes.listItem}>
        <Typography>
          Preço: <strong>{moneyFormat(price)}</strong>
        </Typography>
        <Typography>
          Embalagem:
          <strong>
            {product?.secondUnit}/{product?.fator}
          </strong>
        </Typography>
      </ListItem>

      {discounts.map(discount => {
        return <OrderDiscountItem productDiscounts={discount} key={discount.commission} />;
      })}
    </List>
  );
};

export default OrderListDiscounts;
