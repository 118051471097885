import React from 'react';
import { List } from '@material-ui/core';
import OrderStockSearchItem from './OrderStockSearchItem';

interface OrderStockSearchListProps {
  handleProductStockSelect(product: any): void;
  products: any[];
}

const OrderStockSearchList: React.FC<OrderStockSearchListProps> = ({ handleProductStockSelect, products }) => {
  return (
    <List>
      {products.map(product => {
        return (
          <OrderStockSearchItem
            key={product.productId}
            product={product}
            handleProductStockSelect={handleProductStockSelect}
          />
        );
      })}
    </List>
  );
};

export default OrderStockSearchList;
