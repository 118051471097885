import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { FilterProps } from './Orders';

const styles = makeStyles({
  chip: {
    marginRight: 5,
    marginBottom: 5,
  },
  container: {
    margin: '0 0 10px 0',
  },
});

interface OrdersFilterListProps {
  filters: FilterProps[];
  handleClearFilter(name: string): void;
}

const OrdersFilterList: React.FC<OrdersFilterListProps> = ({ filters, handleClearFilter }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      {filters
        .filter(item => item.value && item.value !== 'T')
        .map(item => (
          <Chip
            color="secondary"
            className={classes.chip}
            label={item.text}
            key={item.name}
            onDelete={() => handleClearFilter(item.name)}
          />
        ))}
    </div>
  );
};

export default OrdersFilterList;
