import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClienteBasic from 'pages/customers/view/ClienteBasic';
import TableNoData from 'components/template/table/TableNoData';
import Tabs from './Tabs';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import Appbar from 'components/appbar/Appbar';
import { useParams } from 'react-router-dom';
import { CustomerEn } from 'types/customer';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import InsideLoading from 'components/loading/InsideLoading';
import InvoiceList from 'pages/invoices/list/InvoiceList';
import PaginationProvider from 'providers/pagination';
import OrderList from 'pages/orders/list/OrderList';

const styles = makeStyles(theme => ({
  N: {
    backgroundColor: '#6bc720',
  },
  S: {
    backgroundColor: '#ff8100',
  },
  chipRoot: {
    marginBottom: 10,
  },
  paper: {
    width: '100%',
    margin: '0 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0px',
    },
  },
  vendasTable: {
    marginTop: 10,
    rowGap: 10,
  },
  tabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
  },
  appbar: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  header: {
    padding: '30px 20px 35px',
    backgroundColor: alpha(theme.palette.secondary.light, 0.7),
    borderRadius: 4,
  },
  headerButton: {
    marginTop: 13,
  },
  statusalign: {
    display: 'flex',
    gap: '5',
  },
}));

type CustomerParams = {
  id: string;
};

const statusOptions = {
  S: 'Ativo',
  N: 'Bloqueado',
};

const Customer: React.FC = () => {
  const classes = styles();
  const [customer, setCustomer] = useState<CustomerEn | null>(null);
  const [orders, setOrders] = useState<any[]>([]);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState(0);
  const { id } = useParams<CustomerParams>();

  useEffect(() => {
    setLoading(true);

    const customerRequest = api.get('/cliente/load', { params: { id } });
    const ordersRequest = api.get('/vendas/getOrderListByCustomer', { params: { idCliente: id } });
    const invoicesRequest = api.get('/titulos/getList', { params: { idCliente: id } });

    Promise.all([customerRequest, ordersRequest, invoicesRequest])
      .then(([customerResponse, ordersResponse, invoicesResponse]) => {
        setCustomer(customerResponse.data.customer);
        setOrders(ordersResponse.data.orders || []);
        setInvoices(invoicesResponse.data.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  function handleTabChange(value: number) {
    setTab(value);
  }

  async function setCustomerStatus() {
    setSaving(true);

    const status = customer?.idStatus === 'N' ? 'S' : 'N';

    const payload = { id: customer?.id, status };

    const form = new FormData();

    Object.keys(payload).forEach(key => form.append(key, payload[key]));

    api
      .post('/cliente/setStatus', form)
      .then(() => {
        if (!customer) {
          return;
        }

        setCustomer({
          ...customer,
          idStatus: status,
          status: statusOptions[status],
        });
      })
      .catch(err => console.log(err))
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      <Appbar title="Clientes" Tab={<Tabs tab={tab} handleTabChange={handleTabChange} />} />

      {saving && <Loading />}

      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.paper}>
          {tab === 0 && (
            <Grid item xs={12}>
              <div className={classes.header}>
                <Typography variant={'h5'}>{customer?.name}</Typography>
                <Typography className={classes.statusalign} variant={'caption'}>
                  {customer?.fantasy}
                </Typography>
                <Typography variant={'caption'}>
                  <strong>{customer?.status}</strong>
                </Typography>
                <div className={classes.headerButton}>
                  <Button disabled={saving} onClick={setCustomerStatus} variant={'contained'} color={'primary'}>
                    {customer?.idStatus === 'S' ? 'Ativar' : 'Bloquear'}
                  </Button>
                </div>
              </div>
            </Grid>
          )}
          <div>
            {tab === 0 && <ClienteBasic customer={customer} />}
            {tab === 1 &&
              (orders.length > 0 ? (
                <div className={classes.vendasTable}>
                  <OrderList orders={orders} />
                </div>
              ) : (
                <TableNoData />
              ))}
            {tab === 2 &&
              (invoices.length > 0 ? (
                <div className={classes.vendasTable}>
                  <PaginationProvider>
                    <InvoiceList invoices={invoices} />
                  </PaginationProvider>
                </div>
              ) : (
                <TableNoData />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Customer;
