import { useEffect, useState } from 'react';
import { api } from 'services/api';

type UseTotalOrders = {
  total: number;
  loading: boolean;
};

export function useTotalOrdersFetch(): UseTotalOrders {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    api
      .get('/dashboard/venda')
      .then(response => {
        setTotal(response.data.value);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return {
    total,
    loading,
  };
}
