import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import { Dispatch, useReducer } from 'react';
import { NewOrder } from 'types/newOrder';
import { OrderProduct } from 'types/product';
import { OrderActions } from './types';

const INITIAL_STATE: NewOrder = {
  id: '',
  customer: null,
  idOperacao: '001',
  idRateio: '4',
  idFormaPagamento: '001',
  observacao: '',
  desconto: 0,
  idCondicaoPagamento: '0010',
  items: [],
};

export function orderReducer(state: NewOrder, action: OrderActions): NewOrder {
  switch (action.type) {
    case 'ORDER_CHANGE': {
      if (action.key === 'idFormaPagamento' && action.value === '002') {
        return {
          ...state,
          idCondicaoPagamento: '0044',
          [action.key]: action.value,
        };
      }

      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case 'SET_ORDER': {
      return action.order;
    }

    case 'SET_ORDER_CUSTOMER': {
      return {
        ...state,
        customer: action.customer,
      };
    }

    case 'ADD_ORDER_PRODUCT': {
      const total = (action.quantity * action.product.price * (100 - action.discount)) / 100;

      const totalfinal = (total * (100 - state.desconto)) / 100;

      const totalbruto = action.quantity * action.product.price;
      const vlCommission = totalfinal * (action.commission / 100);
      const vlDiscount = (totalbruto * action.discount) / 100;
      const secondQuantity = action.quantity / action.product.fator;

      const product: OrderProduct = {
        ...action.product,
        total,
        totalfinal,
        totalbruto,
        vlCommission,
        vlDiscount,
        secondQuantity,
        commission: action.commission,
        discount: action.discount,
        quantity: action.quantity,
        formattedPercentCommission: percentFormat(action.commission),
        formattedPrice: moneyFormat(action.product.price),
        formattedTotal: moneyFormat(total),
        formattedTotalBruto: moneyFormat(totalbruto),
        formattedTotalFinal: moneyFormat(totalfinal),
        formattedVlCommission: moneyFormat(vlCommission),
        formattedVlDiscount: moneyFormat(vlDiscount),
        formattedPercentDiscount: percentFormat(action.discount),
      };

      const isEditMode = state.items.some(item => item.productId === action.product.productId);

      if (!isEditMode) {
        return {
          ...state,
          items: [...state.items, product],
        };
      }

      return {
        ...state,
        items: state.items.map(item => (item.productId === action.product.productId ? product : item)),
      };
    }

    case 'DELETE_ORDER_PRODUCT': {
      return {
        ...state,
        items: state.items.filter(item => !action.productIds.includes(item.productId)),
      };
    }

    case 'SET_NEW_ORDER': {
      return state;
    }

    default: {
      return INITIAL_STATE;
    }
  }
}

type UseOrderReducer = [NewOrder, Dispatch<OrderActions>];

export function useOrderReducer(): UseOrderReducer {
  return useReducer(orderReducer, INITIAL_STATE);
}
