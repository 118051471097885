import { createContext, Dispatch, FormEvent, SetStateAction, useContext } from 'react';
import { ProductDiscount } from 'types/productDiscount';

export interface AddProductContextValue {
  handleReset(): void;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  discount: number;
  setDiscount: Dispatch<SetStateAction<number>>;
  handleSubmit(event?: FormEvent<HTMLFormElement>): Promise<void>;
  price: null | number;
  discounts: ProductDiscount[];
}

const AddProductContext = createContext<AddProductContextValue>({} as AddProductContextValue);
export const AddProductProvider = AddProductContext.Provider;
export const AddProductConsumer = AddProductContext.Consumer;

export function useAddProduct(): AddProductContextValue {
  return useContext(AddProductContext);
}
