import { makeStyles, Typography } from '@material-ui/core';
import { DefaultTheme } from '@material-ui/styles';
import React from 'react';
import { Order } from 'types/Order';

type InvoiceListItemStyleProps = {
  color: string;
};

const styles = makeStyles<DefaultTheme, InvoiceListItemStyleProps>({
  info: {
    display: 'inline-flex',
    gap: 5,
    alignItems: 'center',
  },
  statusColor: props => ({
    backgroundColor: props.color,
    color: '#fff',
    padding: '0 3px',
    borderRadius: 4,
  }),
});

interface OrderListItemInfoProps {
  order: Order;
}

const colorByStatus = {
  A: '#6bc720',
  C: '#ff8100',
  E: '#17a2b8',
};

const OrderListItemInfo: React.FC<OrderListItemInfoProps> = ({ order }) => {
  const classes = styles({ color: colorByStatus[order.situacao] });

  return (
    <Typography component="p" variant="caption" className={classes.info}>
      <span>{order.empresa}</span>

      <span>#</span>

      <span>{order.idPedido}</span>

      <span className={classes.statusColor}>{order.status}</span>
    </Typography>
  );
};

export default OrderListItemInfo;
