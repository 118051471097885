import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Product } from 'types/product';

export interface OrderStockContextValue {
  mode: 'insert' | 'update';
  setMode: Dispatch<SetStateAction<'insert' | 'update'>>;
  selectedProduct: Product | null;
  setSelectedProduct: Dispatch<SetStateAction<Product | null>>;
}

const OrderStockContext = createContext<OrderStockContextValue>({} as OrderStockContextValue);
export const OrderStockConsumer = OrderStockContext.Consumer;
export const OrderStockProvider = OrderStockContext.Provider;

export function useOrderStock(): OrderStockContextValue {
  return useContext(OrderStockContext);
}
