import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { api } from 'services/api';

interface PaymentTermsProps {
  change?: ChangeEventHandler<HTMLInputElement>;
  idCondicaoPagamento: string;
  disabled: boolean;
}

const PaymentTerms: React.FC<PaymentTermsProps> = ({ change, idCondicaoPagamento, disabled }) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    api
      .get('/condicaopagamento')
      .then(response => {
        setData(response.data.condicaopagamento);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <TextField
      disabled={disabled}
      required
      select
      label="Condições de Pagamento"
      value={!data.length ? '' : idCondicaoPagamento}
      onChange={change}
      margin="normal"
      variant="standard"
      fullWidth={true}
    >
      {data.map(item => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.id} - {item.descricao}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default PaymentTerms;
