import React, { Fragment, MouseEvent, useMemo } from 'react';
import { ListItem, Typography, makeStyles, IconButton } from '@material-ui/core';
import { OrderProduct, Product } from 'types/product';
import { StockProduct } from 'types/stock';
import { useDispatch } from 'react-redux';
import { removeProductFromStock } from 'store/modules/stock/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import { useOrderStock } from '../hook/useOrderStock';
import { useOrder } from '../../hooks/useOrder';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
  },
  listItemProductInserted: {
    backgroundColor: theme.palette.secondary.main,
    display: 'block',
    marginBottom: 7,
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  icon: {
    position: 'absolute',
    right: 5,
    bottom: 5,
  },
}));

interface OrderStockItemProps {
  stock: StockProduct;
  orderItems: OrderProduct[];
}

const OrderStockItem: React.FC<OrderStockItemProps> = ({ stock, orderItems }) => {
  const classes = styles();
  const { setSelectedProduct, setMode } = useOrderStock();
  const { setProductToAdd } = useOrder();
  const dispatch = useDispatch();

  function handleClick(stock: Product) {
    setProductToAdd(stock);
    setSelectedProduct(stock);
    setMode('update');
  }

  function handleRemoveProductFromStock(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    dispatch(removeProductFromStock(stock.productId));
  }

  const insertedProduct = useMemo(
    () => orderItems.find(orderItem => orderItem.productId === stock.productId),
    [orderItems, stock]
  );

  return (
    <ListItem
      className={insertedProduct ? classes.listItemProductInserted : classes.listItem}
      button
      key={stock.productId}
      onClick={() => handleClick(stock)}
    >
      <Typography variant="h5">
        {stock.productId} - {stock.description}
      </Typography>
      <Typography variant="caption">
        Estoque: {stock.quantity} {stock.unit}
      </Typography>
      <IconButton
        onClick={handleRemoveProductFromStock}
        className={classes.icon}
        color="inherit"
        type="button"
        title="Remover do estoque"
        size="small"
      >
        <DeleteIcon color="error" />
      </IconButton>

      {insertedProduct && (
        <Fragment>
          <Typography style={{ margin: 10 }} variant="caption">
            Pedido:
            <Typography style={{ margin: 5 }} variant="caption">
              <strong>
                {insertedProduct.quantity} {stock.unit}
              </strong>
            </Typography>
          </Typography>
          <Typography variant="caption">
            <strong>Produto no pedido</strong>
          </Typography>
        </Fragment>
      )}
    </ListItem>
  );
};

export default OrderStockItem;
