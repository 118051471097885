import React, { useMemo } from 'react';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import { Button, List, makeStyles } from '@material-ui/core';
import OrderViewTotalListText from './OrderViewTotalListText';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';

const styles = makeStyles({
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 15,
    marginTop: 15,
  },
  container: {
    height: 330,
  },
});

interface OrderViewTotalListProps {
  order: any;
  onExited: any;
  total: any;
  finalTotal: any;
  discount: any;
  commission: any;
}

const OrderViewTotalList: React.FC<OrderViewTotalListProps> = ({
  order,
  onExited,
  total,
  finalTotal,
  discount,
  commission,
}) => {
  const classes = styles();

  const discountAdd = useMemo(() => moneyFormat(discount), [discount]);
  const discountTotal = useMemo(() => percentFormat((discount / total) * 100), [discount, total]);
  const commissionAdd = useMemo(() => moneyFormat(commission), [commission]);
  const commissionTotal = useMemo(() => percentFormat((commission / finalTotal) * 100), [commission, finalTotal]);
  const final = useMemo(() => moneyFormat(finalTotal), [finalTotal]);

  return (
    <DialogInput maxWidth="sm" onExited={onExited}>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <List>
              <OrderViewTotalListText title="Produtos" text={order.items.length} />
              <OrderViewTotalListText title="SubTotal" text={moneyFormat(total)} />
              {discount > 0 && <OrderViewTotalListText title="Desconto" text={`${discountAdd} (${discountTotal})`} />}
              <OrderViewTotalListText title="Comissão" text={`${commissionAdd} (${commissionTotal})`} />
              <OrderViewTotalListText title="Total" text={final} />
            </List>
            <div className={classes.actions}>
              <Button onClick={context.handleClose} size="small" variant="contained" color="primary">
                Fechar
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default OrderViewTotalList;
