import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IconSearch from '@material-ui/icons/Search';
import SearchLoading from 'components/loading/SearchLoading';
import { api } from 'services/api';
import Dialog from 'components/dialogs/Dialog';
import CustomerSearchList from './CustomerSearchList';
import { Customer } from 'types/customer';

let timer: NodeJS.Timeout;

interface SearchClienteProps {
  handleClose(): void;
  handleSetCustomer(customer: Customer): void;
}

const CustomerSearch: React.FC<SearchClienteProps> = ({ handleClose, handleSetCustomer }) => {
  const [searching, setSearching] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [term, setTerm] = useState('');

  function fetchCustomers(value: string) {
    setSearching(true);

    api
      .get('cliente/getList', { params: { search: value } })
      .then(response => {
        setCustomers(response.data.data || []);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setSearching(false);
      });
  }

  function handleChange(value: string) {
    setTerm(value);

    clearTimeout(timer);

    if (!value) {
      setCustomers([]);
      return false;
    }

    if (value.length < 3) {
      return;
    }

    timer = setTimeout(() => fetchCustomers(value), 500);
  }

  return (
    <Dialog title="Localize o cliente" onExited={handleClose}>
      <TextField
        onChange={e => handleChange(e.target.value)}
        value={term}
        autoFocus
        margin="normal"
        fullWidth
        variant="standard"
        label="Digite o código ou razão do cliente"
        InputProps={{
          startAdornment: <IconSearch />,
        }}
      />

      {searching && <SearchLoading />}

      <CustomerSearchList customers={customers} handleSetCustomer={handleSetCustomer} />
    </Dialog>
  );
};

export default CustomerSearch;
