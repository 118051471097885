import { makeStyles, Typography } from '@material-ui/core';
import { DefaultTheme } from '@material-ui/styles';
import React from 'react';
import { Invoice } from 'types/invoice';

type InvoiceListItemStyleProps = {
  color: string;
};

const styles = makeStyles<DefaultTheme, InvoiceListItemStyleProps>({
  info: {
    display: 'inline-flex',
    gap: 5,
    alignItems: 'center',
  },
  statusColor: props => ({
    backgroundColor: props.color,
    color: '#fff',
    padding: '0 3px',
    borderRadius: 4,
  }),
});

interface InvoiceListItemInfoProps {
  invoice: Invoice;
}

const colorByStatus = {
  A: '#6bc720',
  V: '#ff8100',
  P: '#17a2b8',
};

const InvoiceListItemInfo: React.FC<InvoiceListItemInfoProps> = ({ invoice }) => {
  const classes = styles({ color: colorByStatus[invoice.codSituacao] });

  return (
    <Typography component="p" variant="caption" className={classes.info}>
      <span>{invoice.fantasia}</span>
      <span>/</span>
      <span>
        {invoice.numeroDocumento}-{invoice.parcela}
      </span>
      <span>/</span>
      <span className={classes.statusColor}>{invoice.situacao}</span>
    </Typography>
  );
};

export default InvoiceListItemInfo;
