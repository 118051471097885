import React from 'react';
import OrderViewTotalList from './OrderViewTotalList';

interface OrderViewTotalProps {
  order: any;
  onExited: any;
}

const OrderViewTotal: React.FC<OrderViewTotalProps> = ({ order, onExited }) => {
  let total = 0;
  let finalTotal = 0;
  let discount = 0;
  let commission = 0;

  order.items.forEach(item => {
    total += item.totalbruto;
    finalTotal += item.total;
    discount = total - finalTotal;
    commission += item.vlCommission;
  });

  return (
    <OrderViewTotalList
      order={order}
      onExited={onExited}
      total={total}
      finalTotal={finalTotal}
      discount={discount}
      commission={commission}
    />
  );
};

export default OrderViewTotal;
