import React from 'react';
import OrderViewTotalList from './OrdersViewTotalList';

interface OrderViewTotalProps {
  order: any;
  onExited: any;
}

const OrderViewTotal: React.FC<OrderViewTotalProps> = ({ order, onExited }) => {
  let finalTotal = 0;
  let commission = 0;

  order.forEach(item => {
    finalTotal += item.valor;
    commission += item.vrComissao;
  });

  return <OrderViewTotalList order={order} onExited={onExited} finalTotal={finalTotal} commission={commission} />;
};

export default OrderViewTotal;
