import React, { useMemo } from 'react';
import OrderViewTotalListText from './OrderViewTotalListText';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Button, List, makeStyles } from '@material-ui/core';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';

const styles = makeStyles({
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 15,
    marginTop: 15,
  },
  container: {
    height: 330,
  },
});

interface OrderViewTotalProps {
  order: any;
  onExited: any;
}

const OrderViewTotals: React.FC<OrderViewTotalProps> = ({ order, onExited }) => {
  const classes = styles();
  const discountAdd = useMemo(() => moneyFormat(order.discount), [order.discount]);
  const discountTotal = useMemo(
    () => percentFormat((order.discount / order.finalTotal) * 100),
    [order.discount, order.finalTotal]
  );
  const commissionTotal = useMemo(
    () => percentFormat((order.commission / order.finalTotal) * 100),
    [order.commission, order.finalTotal]
  );
  const commissionAdd = useMemo(() => moneyFormat(order.commission), [order.commission]);
  return (
    <DialogInput maxWidth="sm" onExited={onExited}>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <List>
              <OrderViewTotalListText title="Produtos" text={order.items.length} />
              <OrderViewTotalListText title="SubTotal" text={moneyFormat(order.productTotal)} />
              {order.discount > 0 && (
                <OrderViewTotalListText title="Desconto" text={`${discountAdd} (${discountTotal})`} />
              )}
              {order.vrIcmsSt > 0 && <OrderViewTotalListText title="ICMS ST" text={moneyFormat(order.vrIcmsSt)} />}
              <OrderViewTotalListText title="Comissão" text={`${commissionAdd} (${commissionTotal})`} />
              <OrderViewTotalListText title="Total" text={moneyFormat(order.finalTotal)} />
            </List>
            <div className={classes.actions}>
              <Button onClick={context.handleClose} size="small" variant="contained" color="primary">
                Fechar
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default OrderViewTotals;
