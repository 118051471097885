import React, { useCallback, useEffect, useState } from 'react';
import CustomersFilter from './filter/CustomersFilter';
import CustomerList from './list/CustomerList';
import TableNoData from 'components/template/table/TableNoData';
import CustomersAction from './CustomersAction';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import PageHeader from 'components/page-header/PageHeader';
import PaginationProvider from 'providers/pagination';
import { makeStyles } from '@material-ui/core';
import { Customer } from 'types/customer';
import CustomersFilterList from './filter/CustomersFilterList';
import TableLoading from 'components/loading/TableLoading';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type FilterProps = {
  name: string;
  value: string;
  text: string;
};

const initialFilterValue: FilterProps[] = [
  { name: 'situacao', value: 'T', text: '' },
  { name: 'search', value: '', text: '' },
  { name: 'cidade', value: '', text: '' },
];

const CustomersStatus = {
  N: 'Ativo',
  S: 'Bloqueado',
};

const Customers: React.FC = () => {
  const classes = styles();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filters, setFilters] = useState<FilterProps[]>(initialFilterValue);

  const fetchCustomers = useCallback(async (filters?: FilterProps[]) => {
    setLoading(true);

    const params = new URLSearchParams();

    filters?.forEach(item => {
      params.append(item.name, item.value);
    });

    api
      .get('/cliente/getList', { params })
      .then(response => {
        setCustomers(response.data.data ?? []);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('customerFilters', JSON.stringify(filters));
  }, [filters, fetchCustomers]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    const _filter = localStorage.getItem('customerFilters');

    if (!_filter) {
      return;
    }

    setFilters(JSON.parse(_filter));
  }, []);

  function handleSubmit() {
    fetchCustomers(filters);
    setOpenSearch(false);
  }

  function setInitialStateFilterValue() {
    setFilters(initialFilterValue);
  }

  function handleFilterChange(name: string, value: any) {
    const newfilter = filters.map(item => {
      if (item.name !== name) {
        return item;
      }

      if (name === 'situacao') {
        item.text = CustomersStatus[value];
        item.value = value;
        return item;
      }
      item.value = value;
      item.text = value;
      return item;
    });

    setFilters(newfilter);
  }

  async function handleClearFilter(name: string) {
    const newfilter = filters.map(item => {
      if (name !== item.name) {
        return item;
      }

      return {
        name,
        value: name === 'situacao' ? 'T' : '',
        text: '',
      };
    });

    setFilters(newfilter);

    fetchCustomers(newfilter);
  }

  return (
    <>
      <Appbar title="Clientes" ActionsComponent={<CustomersAction handleOpenSearch={() => setOpenSearch(true)} />} />
      {openSearch && (
        <CustomersFilter
          handleFilterChange={handleFilterChange}
          filters={filters}
          handleSubmit={handleSubmit}
          onExited={() => setOpenSearch(false)}
          setInitialStateFilterValue={setInitialStateFilterValue}
        />
      )}
      <div className={classes.container}>
        <PageHeader title="Clientes" description="Gestão dos clientes" />

        <CustomersFilterList filters={filters} handleClearFilter={handleClearFilter} />

        <PaginationProvider>
          {loading ? <TableLoading /> : !customers.length ? <TableNoData /> : <CustomerList customers={customers} />}
        </PaginationProvider>
      </div>
    </>
  );
};

export default Customers;
