import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import OrderStockList from '../stock/list/OrderStockList';
import ProductListPrices from '../prices/ProductListPrices';

interface AAddProductSearchFormProps {
  handleChange(value: string): void;

  showStock: boolean;
  productPrices: any[];
}

const AddProductSearchForm: React.FC<AAddProductSearchFormProps> = ({ handleChange, showStock, productPrices }) => {
  return showStock ? (
    <OrderStockList />
  ) : (
    <div>
      <TextField
        autoFocus
        onChange={event => handleChange(event.target.value)}
        margin="normal"
        label="Pesquisar produto"
        placeholder="Digite o código do produto"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {productPrices.length > 0 && <ProductListPrices productPrices={productPrices} />}
    </div>
  );
};

export default AddProductSearchForm;
