import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { moneyFormat, numberFormat } from 'helpers/NumberFormat';
import OrderListDiscounts from './discounts/OrderDiscountsList';
import { useOrder } from '../hooks/useOrder';
import { useAddProduct } from './hooks/useAddProduct';
import { Product } from 'types/product';
import { useDialog } from 'components/dialogs/Dialog';
import AddProductStock from './AddProductStock';

const styles = makeStyles({
  container: {
    display: 'grid',
    padding: 20,
  },
  grow: {
    flexGrow: 1,
  },
  form: {
    maxWidth: 300,
  },
});

const AddProductForm: React.FC = () => {
  const classes = styles();
  const [product, setProduct] = useState<Product | null>(null);
  const { productToAdd, productToEdit, isOpenedMixModal } = useOrder();
  const { handleSubmit, quantity, setQuantity, discount, setDiscount, discounts, price, handleReset } = useAddProduct();
  const dialog = useDialog();

  useEffect(() => {
    setProduct(() => {
      if (productToAdd) {
        return productToAdd;
      }

      if (productToEdit) {
        return {
          ...productToEdit,
        };
      }

      return null;
    });
  }, [productToAdd, productToEdit]);

  const formattedDiscount = useMemo(() => {
    if (!price) {
      return '';
    }
    return moneyFormat(price * ((100 - discount) / 100));
  }, [discount, price]);

  function _handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSubmit().then(() => {
      handleReset();

      if (isOpenedMixModal) {
        dialog.handleClose();
      }
    });
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h5">
          {product?.productId} - {product?.description}
        </Typography>

        {discounts && <OrderListDiscounts />}

        <AddProductStock />
      </div>

      <form onSubmit={_handleSubmit} className={classes.form}>
        <TextField
          value={quantity || ''}
          onChange={event => setQuantity(parseInt(event.target.value))}
          autoFocus={true}
          helperText={product ? numberFormat(quantity / product.fator) + ' ' + product?.secondUnit : ''}
          margin="normal"
          required
          placeholder="Quantidade unitária"
          label="Quantidade"
          name="quantity"
          variant="standard"
          type="number"
          inputMode="numeric"
        />
        <TextField
          value={discount || ''}
          onChange={event => setDiscount(parseInt(event.target.value))}
          margin="normal"
          label="% Desconto"
          placeholder="Percentual de desconto"
          variant="standard"
          name="discount"
          inputMode="numeric"
          helperText={`Preço com desconto ${formattedDiscount}`}
          type="number"
        />
        <button type="submit" style={{ display: 'none' }} />
      </form>
    </div>
  );
};

export default AddProductForm;
