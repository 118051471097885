import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';

interface OrdersActionsProps {
  showTotal(): void;
  handleOpenModalSearch(): void;
}

const OrdersAction: React.FC<OrdersActionsProps> = ({ handleOpenModalSearch, showTotal }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  function handleClose() {
    setAnchorEl(null);
  }

  function handleShowTotalClick() {
    showTotal();
    setAnchorEl(null);
  }
  return (
    <div>
      <Tooltip title={'Procurar'}>
        <IconButton onClick={handleOpenModalSearch} color={'inherit'}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Novo pedido'}>
        <IconButton component={Link} to="/new-order" color={'inherit'}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <IconButton onClick={event => setAnchorEl(event.currentTarget)} color={'inherit'}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/weeklyclosing" color={'inherit'} onClick={handleClose}>
          Fechamento
        </MenuItem>
        <MenuItem onClick={() => handleShowTotalClick()}>Ver total</MenuItem>
      </Menu>
    </div>
  );
};

export default OrdersAction;
