import React, { ReactNode, useContext, useState } from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  modal: {
    overflowY: 'auto',
    padding: '0 30px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 40px !important',
    },
  },
  buttonClose: {
    position: 'absolute',
    top: 15,
    right: 10,
    zIndex: 1100,
  },
  root: {
    paddingRight: '0!important',
  },
  appbarSpace: {
    marginBottom: 64,
    [theme.breakpoints.down('md')]: {
      marginBottom: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      marginBottom: 51,
    },
  },
  grow: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: '20px 24px 24px',
  },
}));

interface SimpleModalContextValue {
  handleClose(): void;
}

const SimpleModalContext = React.createContext<SimpleModalContextValue>({} as SimpleModalContextValue);
export const SimpleModalConsumer = SimpleModalContext.Consumer;
export { SimpleModalContext };

export function useSimpleModal(): SimpleModalContextValue {
  return useContext(SimpleModalContext);
}

interface SimpleModalProps {
  handleModalState(): void;
  ActionComponent?: ReactNode;
  title: string;
  children: ReactNode;
  backAction?(): void;
}

const SimpleModal: React.FC<SimpleModalProps> = ({
  handleModalState,
  ActionComponent,
  title,
  children,
  backAction,
}) => {
  const classes = styles();
  const [open, setOpen] = useState(true);

  function handleClose() {
    setOpen(false);
    handleModalState();
  }

  return (
    <SimpleModalContext.Provider value={{ handleClose }}>
      <Dialog classes={{ root: classes.root }} open={open} onClose={handleClose} fullScreen fullWidth>
        {title && (
          <AppBar>
            <Toolbar>
              <IconButton color="inherit" onClick={backAction || handleClose} aria-label="Close">
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" noWrap>
                {title}
              </Typography>

              <div className={classes.grow} />

              {ActionComponent && <div>{ActionComponent}</div>}
            </Toolbar>
          </AppBar>
        )}

        <div className={classes.appbarSpace} />

        <DialogContent classes={{ root: classes.dialogContent }}>{children}</DialogContent>
      </Dialog>
    </SimpleModalContext.Provider>
  );
};

export default SimpleModal;
