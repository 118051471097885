import React, { useState, useContext, useCallback, ReactElement, ReactNode } from 'react';
import { Dialog as NativeDialog, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'hooks/useApp';

const useStyles = makeStyles(theme => ({
  modal: {
    overflowY: 'auto',
    padding: '0 30px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 40px !important',
    },
  },
  root: {
    paddingRight: '0!important',
    position: 'relative',
  },
  appbar: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
    '@media print': {
      display: 'none',
    },
  },
  appbarSpace: {
    marginBottom: 64,
    [theme.breakpoints.down('md')]: {
      marginBottom: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      marginBottom: 48,
    },
  },
  grow: {
    flexGrow: 1,
  },
  paper: ({ backgroundColor, height }: { backgroundColor: string; height: string | number }) => ({
    backgroundColor: backgroundColor || '#fff',
    [theme.breakpoints.up('md')]: {
      height,
    },
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 64,
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 25,
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      marginTop: 48,
    },
    '@media print': {
      padding: 0,
      margin: 0,
    },
  },
}));

type DialogContextData = {
  handleClose(): void;
};

export const DialogContext = React.createContext<DialogContextData>({} as DialogContextData);

export const DialogConsumer = DialogContext.Consumer;

export function useDialog(): DialogContextData {
  const context = useContext(DialogContext);
  return context;
}

type DialogProps = {
  onExited(): void;
  title?: string;
  ComponentActions?: ReactElement;
  backgroundColor?: string;
  hideBackdrop?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  height?: string | number;
  fullScreen?: boolean;
  disableEscapeKeyDown?: boolean;
  onScroll?: () => void;
  children: ReactNode;
  backAction?: () => void;
};

const Dialog: React.FC<DialogProps> = ({
  onExited,
  title,
  ComponentActions,
  children,
  backgroundColor = '#fff',
  hideBackdrop = false,
  maxWidth = 'md',
  height = '100vh',
  fullScreen,
  disableEscapeKeyDown = false,
  onScroll,
  backAction,
}) => {
  const [open, setOpen] = useState(true);
  const app = useApp();
  const styleProps = { backgroundColor, title: !!title, isMobile: app.isMobile, height };
  const classes = useStyles(styleProps);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <NativeDialog
      classes={{ root: classes.root, paper: classes.paper }}
      hideBackdrop={hideBackdrop}
      open={open}
      onClose={handleClose}
      fullWidth
      fullScreen={fullScreen || app.isMobile || app.windowWidth < 960}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      TransitionProps={{ onExited }}
    >
      {title && (
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton color="inherit" onClick={backAction || handleClose}>
              <ArrowBackIcon />
            </IconButton>
            <Typography color="inherit" noWrap>
              {title}
            </Typography>
            <div className={classes.grow} />
            <DialogContext.Provider value={{ handleClose }}>
              <div>{ComponentActions}</div>
            </DialogContext.Provider>
          </Toolbar>
        </AppBar>
      )}
      <DialogContext.Provider value={{ handleClose }}>
        <div className={classes.content} id="native-dialog" onScroll={onScroll}>
          {children}
        </div>
      </DialogContext.Provider>
    </NativeDialog>
  );
};

export default Dialog;
