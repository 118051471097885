import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { FilterProps } from '../Commission';
import CommissionFilterAction from './CommissionFilterAction';
import CommissionFilterForm from './CommissionFilterForm';

interface CommissionFilterFormProps {
  handleFilterChange(name: string, value: any): void;
  filters: FilterProps[];
  handleSubmit(): void;
  onExited(): void;
  setInitialStateFilterValue(): void;
}

const CommissionFilter: React.FC<CommissionFilterFormProps> = ({
  handleFilterChange,
  filters,
  handleSubmit,
  onExited,
  setInitialStateFilterValue,
}) => {
  return (
    <Dialog
      ComponentActions={
        <CommissionFilterAction setInitialStateFilterValue={setInitialStateFilterValue} handleSubmit={handleSubmit} />
      }
      onExited={onExited}
      maxWidth="sm"
      title="Pesquisa"
    >
      <CommissionFilterForm filter={filters} handleFilterChange={handleFilterChange} handleSubmit={handleSubmit} />
    </Dialog>
  );
};

export default CommissionFilter;
