import React, { useState } from 'react';
import { moneyFormat } from 'helpers/NumberFormat';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import InsideLoading from 'components/loading/InsideLoading';
import { useMessaging } from 'providers/messaging';
import WeeklyClosingActions from './WeeklyClosingActions';
import { useFetchWeeklyClosing } from './hooks/useFetchWeeklyClosing';
import Loading from 'components/loading/Loading';
import WeeklyClosingForm from './WeeklyClosingForm';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  alignloading: {
    display: 'flex',
    flex: '1',
  },
  size: {
    display: 'grid',
    flexDirection: 'column',
    maxWidth: '900px',
  },
}));

const WeeklyClosing: React.FC = () => {
  const classes = styles();
  const [observation, setObservation] = useState('');
  const { handleOpen: snackBarShowMessage } = useMessaging();
  const [total, quantity, loading] = useFetchWeeklyClosing();
  const [saving, setSaving] = useState(false);

  function handleSubmit() {
    setSaving(true);

    api
      .post('vendas/sendWeeklyClosing', { quantity, total, observation })
      .then(() => {
        snackBarShowMessage('Fechamento');
      })
      .catch(err => {
        console.error(err);
        snackBarShowMessage('Não foi possível enviar o fechamento');
      })
      .finally(() => setSaving(false));
  }

  return (
    <>
      <Appbar
        title="Fechamento"
        ActionsComponent={<WeeklyClosingActions handleSubmit={handleSubmit} loading={loading || saving} />}
      />

      {saving && <Loading />}

      {loading ? (
        <div className={classes.alignloading}>
          <InsideLoading />
        </div>
      ) : (
        <div className={classes.size}>
          <WeeklyClosingForm
            observation={observation}
            setObservation={setObservation}
            quantity={quantity}
            formattedTotal={moneyFormat(total)}
          />
        </div>
      )}
    </>
  );
};

export default WeeklyClosing;
