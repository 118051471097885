import { createContext, useContext } from 'react';

export type AppContextData = {
  isOpenedMenu: boolean;
  isMobile: boolean;
  handleOpenMenu(): void;
  windowWidth: number;
};

const AppContext = createContext<AppContextData>({} as AppContextData);
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

export function useApp(): AppContextData {
  const context = useContext(AppContext);
  return context;
}

export default AppContext;
