import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { FilterProps } from '../Orders';
import OrdersFilterAction from '../OrdersFilterAction';
import OrdersFilterForm from './OrdersFilterForm';

interface OrdersFilterFormProps {
  handleFilterChange(name: string, value: any): void;
  filters: FilterProps[];
  handleSubmit(): void;
  onExited(): void;
  setInitialStateFilterValue(): void;
}

const OrdersFilter: React.FC<OrdersFilterFormProps> = ({
  handleFilterChange,
  filters,
  handleSubmit,
  onExited,
  setInitialStateFilterValue,
}) => {
  return (
    <Dialog
      ComponentActions={
        <OrdersFilterAction setInitialStateFilterValue={setInitialStateFilterValue} handleSubmit={handleSubmit} />
      }
      onExited={onExited}
      maxWidth="sm"
      title="Pesquisa"
    >
      <OrdersFilterForm filter={filters} handleFilterChange={handleFilterChange} handleSubmit={handleSubmit} />
    </Dialog>
  );
};

export default OrdersFilter;
