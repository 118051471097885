import React from 'react';
import List from '@material-ui/core/List';
import SimpleModal from 'components/template/modal/SimpleModal';
import OrderMixItem from './OrderMixItem';
import { useOrder } from '../hooks/useOrder';

interface OrderMixModalProps {
  onExited(): void;
}

const OrderMix: React.FC<OrderMixModalProps> = ({ onExited }) => {
  const { productsMix } = useOrder();

  return (
    <SimpleModal title="Produto do mix" handleModalState={onExited}>
      <List>
        {productsMix.map(item => (
          <OrderMixItem item={item} key={item.productId} />
        ))}
      </List>
    </SimpleModal>
  );
};

export default OrderMix;
