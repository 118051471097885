import React, { useCallback, useEffect, useState } from 'react';
import TableNoData from 'components/template/table/TableNoData';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import PageHeader from 'components/page-header/PageHeader';
import PaginationProvider from 'providers/pagination';
import { makeStyles } from '@material-ui/core';

import CommissionList from './list/CommissionList';
import { addDays, subDays } from 'date-fns';
import { dateFormat } from 'helpers/DateFormat';
import CommissionAction from './CommissionAction';
import CommissionFilterList from './filter/CommissionFilterList';
import CommissionFilter from './filter/CommissionFilter';
import TableLoading from 'components/loading/TableLoading';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type FilterProps = {
  name: string;
  value: string;
  text: string;
};

const initialDate = subDays(new Date(), 90);
const finalDate = addDays(new Date(), 60);

const initialFilterValue: FilterProps[] = [
  { name: 'dataInicial', value: initialDate.toISOString(), text: dateFormat(initialDate) },
  { name: 'dataFinal', value: finalDate.toISOString(), text: dateFormat(finalDate) },
  { name: 'situacao', value: 'T', text: '' },
  { name: 'search', value: '', text: '' },
  { name: 'empresa', value: '', text: '' },
];

const commissionStatus = {
  A: 'Aberto',
  V: 'Vencido',
  P: 'Liquidado',
};

const Commission: React.FC = () => {
  const classes = styles();
  const [commission, setCommission] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filters, setFilters] = useState<FilterProps[]>(initialFilterValue);

  const fetchInvoices = useCallback(async (filters?: FilterProps[]) => {
    setLoading(true);

    const params = new URLSearchParams();

    filters?.forEach(item => {
      params.append(item.name, item.value);
    });

    api
      .get('comissao', { params })
      .then(response => {
        setCommission(response.data.data ?? []);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('commissionFilters', JSON.stringify(filters));
  }, [filters, fetchInvoices]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    const _filter = localStorage.getItem('commissionFilters');

    if (!_filter) {
      return;
    }

    setFilters(JSON.parse(_filter));
  }, []);

  function handleSubmit() {
    fetchInvoices(filters);
    setOpenSearch(false);
  }

  function setInitialStateFilterValue() {
    setFilters(initialFilterValue);
  }

  function handleFilterChange(name: string, value: any) {
    const newfilter = filters.map(item => {
      if (item.name !== name) {
        return item;
      }

      if (name === 'dataInicial' || name === 'dataFinal') {
        item.text = dateFormat(value);
        item.value = value ? value.toISOString() : '';
        return item;
      }

      if (name === 'situacao') {
        item.text = commissionStatus[value];
        item.value = value;
        return item;
      }

      item.value = value;
      item.text = value;
      return item;
    });

    setFilters(newfilter);
  }

  async function handleClearFilter(name: string) {
    const _filters = filters.map(item => {
      if (name !== item.name) {
        return item;
      }

      return {
        name,
        value: name === 'situacao' ? 'T' : '',
        text: '',
      };
    });

    setFilters(_filters);

    fetchInvoices(_filters);
  }

  return (
    <>
      <Appbar title="Comissâo" ActionsComponent={<CommissionAction handleOpenSearch={() => setOpenSearch(true)} />} />
      {openSearch && (
        <CommissionFilter
          handleFilterChange={handleFilterChange}
          filters={filters}
          handleSubmit={handleSubmit}
          onExited={() => setOpenSearch(false)}
          setInitialStateFilterValue={setInitialStateFilterValue}
        />
      )}
      <div className={classes.container}>
        <PageHeader title="Comissão" description="Gestão das comissões" />

        <CommissionFilterList filters={filters} handleClearFilter={handleClearFilter} />

        <PaginationProvider>
          {loading ? (
            <TableLoading />
          ) : !commission.length ? (
            <TableNoData />
          ) : (
            <CommissionList commission={commission} />
          )}
        </PaginationProvider>
      </div>
    </>
  );
};

export default Commission;
