import { Stock } from 'types/stock';
import { StockActions } from './types';

export const INITIAL_STATE: Stock = {
  customerId: null,
  products: [],
};

export default function stockReducer(state = INITIAL_STATE, action: StockActions): Stock {
  switch (action.type) {
    case 'stock.ADD_PRODUCT_TO_STOCK': {
      const product = state.products.find(product => product.productId === action.product.productId);

      if (product) {
        return {
          ...state,
          products: state.products.map(product => {
            if (product.productId === action.product.productId) {
              return {
                ...product,
                quantity: product.quantity + action.quantity,
              };
            }
            return product;
          }),
        };
      }

      return {
        ...state,
        products: [...state.products, { ...action.product, quantity: action.quantity }],
      };
    }

    case 'stock.REMOVE_PRODUCT_FROM_STOCK': {
      return {
        ...state,
        products: state.products.filter(product => product.productId !== action.productId),
      };
    }

    case 'stock.UPDATE_PRODUCT_FROM_STOCK': {
      return {
        ...state,
        products: state.products.map(product => {
          if (product.productId === action.productId) {
            return {
              ...product,
              quantity: action.quantity,
            };
          }

          return product;
        }),
      };
    }

    case 'stock.DELETE_STOCK': {
      return INITIAL_STATE;
    }

    case 'stock.SET_CUSTOMER_TO_STOCK': {
      return {
        ...state,
        customerId: state.customerId,
      };
    }

    default: {
      return state;
    }
  }
}
