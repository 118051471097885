import React, { useState, useEffect } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { api } from 'services/api';

interface FormasPagamentoProps {
  change?: any;
  idFormaPagamento: string;
  disabled: boolean;
}

const PaymentMethod: React.FC<FormasPagamentoProps> = ({ change, idFormaPagamento, disabled }) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    api
      .get('/formapagamento')
      .then(response => {
        setData(response.data.formapagamento);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <TextField
      select
      label="Forma de Pagamento"
      required
      disabled={disabled}
      value={!data.length ? '' : idFormaPagamento}
      onChange={change}
      margin="normal"
      variant="standard"
      fullWidth={true}
    >
      {data.map(item => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.id} - {item.descricao}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default PaymentMethod;
