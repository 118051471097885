import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import IconCheck from '@material-ui/icons/Check';

interface WeeklyClosingActionsProps {
  handleSubmit(): void;
  loading: boolean;
}

const WeeklyClosingActions: React.FC<WeeklyClosingActionsProps> = ({ handleSubmit, loading }) => {
  return (
    <Tooltip title="Enviar fechamento">
      <div>
        <IconButton disabled={loading} onClick={handleSubmit} color="inherit">
          <IconCheck />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default WeeklyClosingActions;
