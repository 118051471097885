import { jwtDecode } from './jwtDecode';

class UserDataToken {
  dataToken = {
    data: {
      username: '',
      name: '',
      levelPermission: 1,
    },
  };

  constructor() {
    let datatoken = localStorage.getItem('token');
    if (datatoken) {
      try {
        datatoken = jwtDecode(localStorage.getItem('token'));
        this.dataToken = datatoken;
      } catch (e) {
        console.log(e);
      }
    }
  }

  /**
   * Get username from token (JWT)
   * @returns {string}
   */
  getUsername() {
    return this.dataToken.data.username;
  }

  /**
   * Get name from token (JWT)
   * @returns {string}
   */
  getName() {
    return this.dataToken.data.name;
  }

  /**
   * Return user permission.
   * @returns {string}
   */
  getPermission() {
    return this.dataToken.data.levelPermission;
  }
}

export default UserDataToken;
