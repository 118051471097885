import React, { useMemo } from 'react';
import { makeStyles, Typography, ListItem } from '@material-ui/core';
import { useOrder } from '../hooks/useOrder';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
    minHeight: 88,
  },
  listItemProductInserted: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  icon: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
  space: {
    display: 'grid',
  },
}));

interface OrderMixItemProps {
  item: any;
}

const OrderMixItem: React.FC<OrderMixItemProps> = ({ item }) => {
  const classes = styles();
  const { order, setProductToAdd, setIsOpenedAddProductModal } = useOrder();

  const productInserted = useMemo(
    () => order.items.find(orderProduct => orderProduct.productId === item.productId),
    [order, item]
  );

  function handleAddProductMix() {
    setProductToAdd(item);
    setIsOpenedAddProductModal(true);
  }

  return (
    <ListItem
      onClick={() => handleAddProductMix()}
      className={productInserted ? `${classes.listItem} ${classes.listItemProductInserted}` : classes.listItem}
      button
      key={item.productId}
    >
      <Typography variant="h6">
        {item.productId} - {item.description}
      </Typography>

      {productInserted ? (
        <Typography variant="caption">
          <strong>Produto no pedido</strong>
        </Typography>
      ) : (
        <div className={classes.space}>
          <Typography variant="caption">
            Maior quantidade: {item.quantity}
            {item.unit}
          </Typography>
          <Typography variant="caption">
            Última compra em <strong>{item.lastDate}</strong>
          </Typography>
        </div>
      )}
    </ListItem>
  );
};

export default OrderMixItem;
