import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useOrder } from '../hooks/useOrder';

interface OrderTabsProps {
  tabvalue: any;
  handleTabChange(tab: number): any;
  customer: any;
}

const OrderTabs: React.FC<OrderTabsProps> = ({ tabvalue, handleTabChange, customer }) => {
  const { order } = useOrder();

  return (
    <Tabs value={tabvalue} onChange={(event, value) => handleTabChange(value)}>
      <Tab label="Cadastro" />
      <Tab label="Produtos" disabled={!order?.customer && !customer} />
    </Tabs>
  );
};

export default OrderTabs;
