import { IconButton, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useOrder } from '../hooks/useOrder';
import MoreIcon from '@material-ui/icons/MoreVert';

interface OrderActionButtonMoreProps {
  showTotal(): void;
  selectAll(): void;
}

const OrderActionButtonMore: React.FC<OrderActionButtonMoreProps> = ({ showTotal, selectAll }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { selectedProducts, order } = useOrder();

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function handleShowTotalClick() {
    showTotal();
    handleCloseMenu();
  }

  function handleSelectAllClick() {
    selectAll();
    handleCloseMenu();
  }

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={() => handleShowTotalClick()}>Ver total</MenuItem>
        {selectedProducts.length !== order.items.length && (
          <MenuItem onClick={() => handleSelectAllClick()}>Selecionar todos</MenuItem>
        )}
      </Menu>

      <IconButton color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
        <MoreIcon />
      </IconButton>
    </>
  );
};

export default OrderActionButtonMore;
