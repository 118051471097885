import Dialog from 'components/dialogs/Dialog';
import React from 'react';
import { FilterProps } from '../Customers';
import CustomersFilterAction from './CustomersFilterAction';
import CustomersFilterForm from './CustomersFilterForm';

interface CustomersFilterFormProps {
  handleFilterChange(name: string, value: any): void;
  filters: FilterProps[];
  handleSubmit(): void;
  onExited(): void;
  setInitialStateFilterValue(): void;
}

const CustomersFilter: React.FC<CustomersFilterFormProps> = ({
  handleFilterChange,
  filters,
  handleSubmit,
  onExited,
  setInitialStateFilterValue,
}) => {
  return (
    <Dialog
      ComponentActions={
        <CustomersFilterAction setInitialStateFilterValue={setInitialStateFilterValue} handleSubmit={handleSubmit} />
      }
      onExited={onExited}
      maxWidth="sm"
      title="Pesquisa"
    >
      <CustomersFilterForm filter={filters} handleFilterChange={handleFilterChange} handleSubmit={handleSubmit} />
    </Dialog>
  );
};

export default CustomersFilter;
