import React, { useState, useEffect, useRef } from 'react';
import { TextField, InputAdornment, makeStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { LoginValidation } from './Login';

const useStyles = makeStyles({
  icon: {
    cursor: 'pointer',
  },
});

type LoginFormProps = {
  username: string;
  password: string;
  setUsername(username: string): void;
  setPassword(password: string): void;
  validation: LoginValidation;
};

const LoginForm: React.FC<LoginFormProps> = ({ username, password, setUsername, setPassword, validation }) => {
  const classes = useStyles();
  const [passVisibility, setPassVisibility] = useState(true);
  const inputs = {
    email: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function handlePasswordVisibility() {
    setPassVisibility(!passVisibility);
  }

  return (
    <>
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="Nome de usuário ou e-mail"
        placeholder="Seu nome de usuário ou e-mail"
        margin="normal"
        onChange={e => setUsername(e.target.value)}
        value={username}
        autoComplete="username"
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <PersonIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        inputRef={inputs.password}
        error={!!validation.password}
        helperText={validation.password}
        label="Senha"
        placeholder="Sua senha"
        margin="normal"
        onChange={e => setPassword(e.target.value)}
        value={password}
        type={passVisibility ? 'password' : 'text'}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {passVisibility ? (
                <VisibilityIcon className={classes.icon} onClick={handlePasswordVisibility} color="primary" />
              ) : (
                <VisibilityOffIcon className={classes.icon} onClick={handlePasswordVisibility} color="primary" />
              )}
            </InputAdornment>
          ),
        }}
      />
      <button type="submit" style={{ display: 'none' }} />
    </>
  );
};

export default LoginForm;
