import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'store/selector';
import { Product } from 'types/product';
import { useOrder } from '../hooks/useOrder';

const AddProductStock: React.FC = () => {
  const { productToAdd, productToEdit } = useOrder();
  const stock = useSelector(state => state.stock);

  const product = useMemo((): Product | null => {
    if (productToAdd) {
      return productToAdd;
    }

    if (productToEdit) {
      return { ...productToEdit };
    }

    return null;
  }, [productToAdd, productToEdit]);

  const stockProduct = useMemo(
    () => stock.products.find(item => item.productId === product?.productId),
    [stock, product]
  );

  return (
    <div>
      <Typography>
        Estoque: <strong>{stockProduct ? `${stockProduct?.quantity} ${stockProduct?.unit}` : 'Não informado'}</strong>
      </Typography>
    </div>
  );
};

export default AddProductStock;
