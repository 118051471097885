import React, { useCallback, useEffect, useState } from 'react';
import TableNoData from 'components/template/table/TableNoData';
import TableLoading from 'components/loading/TableLoading';
import { makeStyles } from '@material-ui/core/styles';
import { dateFormat } from 'helpers/DateFormat';
import Appbar from 'components/appbar/Appbar';
import { addDays, subDays } from 'date-fns';
import { api } from 'services/api';
import PaginationProvider from 'providers/pagination';
import PageHeader from 'components/page-header/PageHeader';
import OrdersAction from './OrdersAction';
import OrdersFilter from './filter/OrdersFilter';
import OrdersFilterList from './OrdersFilterList';
import OrderList from './list/OrderList';
import OrderViewTotal from './OrdersViewTotal';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

export type FilterProps = {
  name: string;
  value: string;
  text: string;
};

const date = new Date();
const today = date.getDay();

const toFriday = 5 - today;
const toMonday = today - 1;

const initialDate = new Date();
const finalDate = new Date();

finalDate.setDate(finalDate.getDate() + toFriday);
initialDate.setDate(initialDate.getDate() - toMonday);

const initialFilterValue: FilterProps[] = [
  { name: 'dataInicial', value: initialDate.toISOString(), text: dateFormat(initialDate) },
  { name: 'dataFinal', value: finalDate.toISOString(), text: dateFormat(finalDate) },
  { name: 'situacao', value: 'T', text: '' },
  { name: 'search', value: '', text: '' },
];

const orderStatus = {
  A: 'Aberto',
  E: 'Encerrado',
  C: 'Cancelado',
};

const Orders: React.FC = () => {
  const classes = styles();
  const [filters, setFilters] = useState<FilterProps[]>(initialFilterValue);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [openModalSearch, setopenModalSearch] = useState(false);
  const [showTotal, setShowTotal] = useState(false);

  const fetchOrders = useCallback(async (filters?: FilterProps[]) => {
    setLoading(true);

    const params = new URLSearchParams();

    filters?.forEach(item => {
      params.append(item.name, item.value);
    });

    api
      .get('vendas', { params })
      .then(response => {
        setOrders(response.data?.order?.item ?? []);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('ordersFilters', JSON.stringify(filters));
  }, [filters, fetchOrders]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    const _filter = localStorage.getItem('OrdersFilters');

    if (!_filter) {
      return;
    }

    setFilters(JSON.parse(_filter));
  }, []);

  function handleSubmit() {
    fetchOrders(filters);
    setopenModalSearch(false);
  }

  function setInitialStateFilterValue() {
    setFilters(initialFilterValue);
  }

  function handleShowTotal() {
    setShowTotal(state => !state);
  }

  function handleFilterChange(name: string, value: any) {
    const newfilter = filters.map(item => {
      if (item.name !== name) {
        return item;
      }

      if (name === 'dataInicial' || name === 'dataFinal') {
        item.text = dateFormat(value);
        item.value = value ? value.toISOString() : '';
        return item;
      }

      if (name === 'situacao') {
        item.text = orderStatus[value];
        item.value = value;
        return item;
      }

      item.value = value;
      item.text = value;
      return item;
    });

    setFilters(newfilter);
  }

  async function handleClearFilter(name: string) {
    const _filters = filters.map(item => {
      if (name !== item.name) {
        return item;
      }

      return {
        name,
        value: name === 'situacao' ? 'T' : '',
        text: '',
      };
    });

    setFilters(_filters);

    fetchOrders(_filters);
  }

  return (
    <>
      <Appbar
        title="Vendas"
        ActionsComponent={
          <OrdersAction handleOpenModalSearch={() => setopenModalSearch(true)} showTotal={handleShowTotal} />
        }
      />

      {openModalSearch && (
        <OrdersFilter
          handleFilterChange={handleFilterChange}
          filters={filters}
          handleSubmit={handleSubmit}
          onExited={() => setopenModalSearch(false)}
          setInitialStateFilterValue={setInitialStateFilterValue}
        />
      )}
      {showTotal && <OrderViewTotal order={orders} onExited={handleShowTotal} />}
      <div className={classes.container}>
        <PageHeader title="Vendas" description="Gestão das vendas" />

        <OrdersFilterList filters={filters} handleClearFilter={handleClearFilter} />

        <PaginationProvider>
          {loading ? <TableLoading /> : !orders.length ? <TableNoData /> : <OrderList orders={orders} />}
        </PaginationProvider>
      </div>
    </>
  );
};

export default Orders;
