import React, { useMemo } from 'react';
import List from '@material-ui/core/List';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Pagination from 'components/pagination/Pagination';
import OrderListItem from './OrderListItem';
import { usePagination } from 'providers/pagination';
import { Order } from 'types/Order';
import { useListStyle } from '../../../providers/listStyle';

type Styles = {
  listStyle: string;
};

const styles = makeStyles<Theme, Styles>(theme => ({
  list: props => ({
    display: 'grid',
    gridTemplateColumns: props.listStyle === 'grid' ? 'repeat(auto-fill, minmax(400px, 1fr))' : '1fr',
    gridAutoRows: 'min-content',
    gap: '10px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: props.listStyle === 'grid' ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr',
    },
  }),
}));

interface OrdersListProps {
  orders: Order[];
}

type PaginationValues = {
  start: number;
  end: number;
};

const OrderList: React.FC<OrdersListProps> = ({ orders }) => {
  const { listStyle } = useListStyle();
  const classes = styles({ listStyle });
  const { rowsPerPage, page } = usePagination();

  const pagination = useMemo((): PaginationValues => {
    return {
      start: page * rowsPerPage,
      end: page * rowsPerPage + rowsPerPage,
    };
  }, [page, rowsPerPage]);

  return (
    <>
      <List className={classes.list} disablePadding>
        {orders.slice(pagination.start, pagination.end).map(order => (
          <OrderListItem order={order} key={order.id} />
        ))}
      </List>

      <Pagination count={orders.length} />
    </>
  );
};

export default OrderList;
