import React, { FormEvent, useMemo } from 'react';
import { TextField, InputAdornment, makeStyles } from '@material-ui/core';
import IconSearch from '@material-ui/icons/Search';
import { FilterProps } from '../Commission';
import MobileDatePicker from 'components/pickers/MobileDatePicker';

const styles = makeStyles({
  form: {},
});

interface CommissionFilterFormProps {
  handleFilterChange(name: string, value: any): void;
  filter: FilterProps[];
  handleSubmit(): void;
}

const CommissionFilterForm: React.FC<CommissionFilterFormProps> = ({
  handleFilterChange,
  filter,
  handleSubmit: searchHandleSubmit,
}) => {
  const classes = styles();

  const initialDate = useMemo(() => {
    return filter[0].value ? new Date(filter[0].value) : null;
  }, [filter]);

  const finalDate = useMemo(() => {
    return filter[1].value ? new Date(filter[1].value) : null;
  }, [filter]);

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    searchHandleSubmit();
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        autoFocus
        label={'Título'}
        fullWidth
        autoComplete="off"
        value={filter[3].value}
        onChange={e => handleFilterChange('search', e.target.value)}
        placeholder={'Digite a razão ou o código do título'}
        margin={'normal'}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <IconSearch />
            </InputAdornment>
          ),
        }}
      />

      <MobileDatePicker
        label="Data Inicial"
        value={initialDate}
        onChange={date => handleFilterChange('dataInicial', date)}
        autoOk
        clearable
        clearLabel="Limpar"
      />

      <MobileDatePicker
        label="Data Final"
        value={finalDate}
        onChange={date => handleFilterChange('dataFinal', date)}
        autoOk
        clearable
        clearLabel="Limpar"
      />

      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default CommissionFilterForm;
