import { Customer } from 'types/customer';
import { Product } from 'types/product';
import { StockActions } from './types';

export function addProductToStock(product: Product, quantity: number): StockActions {
  return {
    type: 'stock.ADD_PRODUCT_TO_STOCK',
    product,
    quantity,
  };
}

export function removeProductFromStock(productId: string): StockActions {
  return {
    type: 'stock.REMOVE_PRODUCT_FROM_STOCK',
    productId,
  };
}

export function updateProductFromStock(productId: string, quantity: number): StockActions {
  return {
    type: 'stock.UPDATE_PRODUCT_FROM_STOCK',
    quantity,
    productId,
  };
}

export function deleteStock(): StockActions {
  return {
    type: 'stock.DELETE_STOCK',
  };
}

export function setCustomerToStock(customer: Customer): StockActions {
  return {
    type: 'stock.SET_CUSTOMER_TO_STOCK',
    customer,
  };
}
