import { useEffect, useState } from 'react';
import { api } from 'services/api';

export type UseFetchWeeklyClosing = [number, number, boolean, string];

export function useFetchWeeklyClosing(): UseFetchWeeklyClosing {
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    api
      .get('/vendas/weeklyClosing')
      .then(response => {
        setTotal(response.data.total);
        setQuantity(response.data.quantity);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setError(err.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [total, quantity, loading, error];
}
