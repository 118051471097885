import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/es/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { moneyFormat, numberFormat } from '../../helpers/NumberFormat';
import { makeStyles } from '@material-ui/styles';
import { api } from 'services/api';

const styles = makeStyles({
  paperChart: {
    width: '100%',
    position: 'relative',
    padding: '20px 10px 10px 10px',
    boxSizing: 'border-box',
  },
  loadingChart: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%)',
  },
});

const SalesChart: React.FC = () => {
  const classes = styles();
  const [chartHeight, setChartHeight] = useState('400px');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setChartHeight('auto');
  }, [loading]);

  useEffect(() => {
    api
      .get('/dashboard/chart')
      .then(response => {
        const _data = response.data.data.map(item => ({
          name: item.name,
          Vendas: parseFloat(item.value),
        }));
        setData(_data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Paper className={classes.paperChart} style={{ height: chartHeight }}>
      <Typography style={{ marginBottom: 30 }}>Evolução das Vendas</Typography>

      {loading ? (
        <div className={classes.loadingChart}>
          <CircularProgress color={'primary'} />
        </div>
      ) : (
        <ResponsiveContainer>
          <BarChart width={730} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={value => numberFormat(value)} />
            <Tooltip formatter={value => moneyFormat(value)} />
            <Legend />
            <Bar dataKey="Vendas" fill="#ffc107" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
};

export default SalesChart;
