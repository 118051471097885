import React, { FormEvent } from 'react';
import { makeStyles, MenuItem, InputAdornment, TextField } from '@material-ui/core';
import IconSearch from '@material-ui/icons/Search';
import { FilterProps } from '../Customers';

const styles = makeStyles({
  form: {},
});
interface CustomersFilterProps {
  handleFilterChange(name: string, value: any): void;
  filter: FilterProps[];
  handleSubmit(): void;
}

const CustomersFilterForm: React.FC<CustomersFilterProps> = ({
  handleFilterChange,
  filter,
  handleSubmit: searchHandleSubmit,
}) => {
  const classes = styles();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    searchHandleSubmit();
  }

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        autoFocus
        label={'Cliente'}
        fullWidth
        autoComplete="off"
        value={filter[1].value}
        onChange={e => handleFilterChange('search', e.target.value)}
        placeholder={'Digite a razão ou o código do cliente'}
        margin={'normal'}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <IconSearch />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        label={'Cidade'}
        value={filter[2].value}
        onChange={e => handleFilterChange('cidade', e.target.value)}
        name="cidade"
        autoComplete="off"
        margin={'normal'}
        variant={'standard'}
      />

      <TextField
        variant={'standard'}
        select
        fullWidth
        label={'Situação'}
        margin={'normal'}
        name="situacao"
        value={filter[0].value}
        onChange={e => handleFilterChange('situacao', e.target.value)}
      >
        <MenuItem value="T">Todas as Situções</MenuItem>
        <MenuItem value="N">Ativo</MenuItem>
        <MenuItem value="S">Bloqueado</MenuItem>
      </TextField>

      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default CustomersFilterForm;
