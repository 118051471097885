import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { makeStyles, Grid, TextField, Typography } from '@material-ui/core';
import { numberFormat } from 'helpers/NumberFormat';
import { Product } from 'types/product';

const styles = makeStyles({
  productInfo: {
    marginBottom: 20,
  },
  grow: {
    flexGrow: 1,
  },
});

interface OrderStockFormProps {
  selectedProduct: Product;
  handleAddProductToStock(): void;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
}

const OrderStockForm: React.FC<OrderStockFormProps> = ({
  selectedProduct,
  handleAddProductToStock,
  quantity,
  setQuantity,
}) => {
  const classes = styles();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    handleAddProductToStock();
  }

  return (
    <>
      <div className={classes.productInfo}>
        <Typography variant="h5">
          {selectedProduct.productId} - {selectedProduct.description}
        </Typography>
        <Typography variant="caption">
          Embalagem:{' '}
          <strong>
            {selectedProduct.secondUnit}/{selectedProduct.fator}
          </strong>
        </Typography>
      </div>
      <Grid item xl={4}>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus={true}
            margin="normal"
            required
            helperText={numberFormat(quantity / selectedProduct.fator) + ' ' + selectedProduct.secondUnit}
            label="Quantidade"
            variant="standard"
            fullWidth
            type="number"
            name="quantity"
            onChange={e => setQuantity(parseInt(e.target.value))}
            value={quantity || ''}
          />
          <button style={{ display: 'none' }} type="submit">
            Salvar
          </button>
        </form>
      </Grid>
    </>
  );
};

export default OrderStockForm;
