import React from 'react';
import { List } from '@material-ui/core';
import CustomerSearchItem from './CustomerSearchItem';
import { Customer } from 'types/customer';

interface SearchClienteProps {
  customers: any[];
  handleSetCustomer(customer: Customer): void;
}

const CustomerSearchList: React.FC<SearchClienteProps> = ({ customers, handleSetCustomer }) => {
  return (
    <List component="nav">
      {customers.map(customer => (
        <CustomerSearchItem key={customer.idCliente} customer={customer} handleSetCustomer={handleSetCustomer} />
      ))}
    </List>
  );
};

export default CustomerSearchList;
