import React, { useState } from 'react';
import { Fab, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useOrder } from '../hooks/useOrder';

const styles = makeStyles(theme => ({
  fabAdd: {
    position: 'fixed',
    right: '10%',
    bottom: '15%',
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      right: '10%',
      bottom: '15%',
    },
  },
  chipPrice: {
    margin: '10px 0',
  },
  chipColorPrimary: {
    backgroundColor: '#17a2b8',
  },
}));

const FabButton: React.FC = () => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setIsOpenedAddProductModal, setIsOpenedMixModal, setIsOpenedStockModal, productsMix } = useOrder();

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function handleAddProductClick() {
    setIsOpenedAddProductModal(true);
    handleCloseMenu();
  }

  function handleLoadMixClick() {
    setIsOpenedMixModal(true);
    handleCloseMenu();
  }

  function handleAddStockClick() {
    setIsOpenedStockModal(true);
    handleCloseMenu();
  }

  return (
    <div>
      <Menu id="menu-OrderItem" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={() => handleAddProductClick()}>Incluir um produto</MenuItem>
        {productsMix.length > 0 && <MenuItem onClick={() => handleLoadMixClick()}>Carregar mix</MenuItem>}
        <MenuItem onClick={() => handleAddStockClick()}>Incluir estoque</MenuItem>
      </Menu>

      <Fab className={classes.fabAdd} onClick={event => setAnchorEl(event.currentTarget)} color="primary" size="large">
        <Add />
      </Fab>
    </div>
  );
};

export default FabButton;
