import React, { useState } from 'react';
import { Avatar, makeStyles, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CustomerIcon from '@material-ui/icons/SupervisorAccount';
import Drawer from '@material-ui/core/Drawer';
import DescriptionIcon from '@material-ui/icons/Description';
import SidebarItem from './SidebarItem';
import { useApp } from 'hooks/useApp';
import { SIDEBAR_WIDTH } from 'constants/constants';
import SalesSidebarItem from './collapsible/SalesSidebarItem';
import { useSelector } from 'store/selector';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    backgroundColor: theme.palette.secondary.main,
    '@media print': {
      display: 'none',
    },
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      height: 56,
    },
  },
  avatar: {
    width: 40,
    height: 40,
    border: `2px solid ${theme.palette.secondary.light}`,
  },
  logout: {
    position: 'absolute',
    bottom: 0,
  },
  account: {
    minHeight: 90,
    marginTop: 30,
  },
  content: {
    transition: 'opacity 0.4s ease',
    opacity: 1,
  },
}));

export type SideBarCollapsible = {
  sale: boolean;
};

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const { isMobile, isOpenedMenu, handleOpenMenu } = useApp();
  const [collapsible, setCollapsible] = useState<SideBarCollapsible>({
    sale: false,
  });
  const user = useSelector(state => state.user);

  function handleCollapseClick(index: keyof SideBarCollapsible, closeOthers = true): void {
    if (closeOthers) {
      const keys = Object.keys(collapsible);

      let otherValues: SideBarCollapsible = {} as SideBarCollapsible;
      keys.forEach(key => {
        otherValues = {
          ...otherValues,
          [key]: false,
        };
      });

      setCollapsible({
        ...otherValues,
        [index]: !collapsible[index],
      });
      return;
    }

    setCollapsible({
      ...collapsible,
      [index]: !collapsible[index],
    });
  }

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      open={isOpenedMenu}
      onClose={handleOpenMenu}
    >
      <div className={classes.content}>
        <div className={classes.drawerHeader}>
          <Typography variant="h6" color="inherit">
            PedidoWeb
          </Typography>
        </div>
        <SidebarItem to="/dashboard" button text="Dashboard" icon={<DashboardIcon />} />
        <SalesSidebarItem handleCollapseClick={handleCollapseClick} collapsible={collapsible} />
        <SidebarItem to="/customers" button text="Clientes" icon={<CustomerIcon />} />
        <SidebarItem to="/invoices" button text="Títulos" icon={<DescriptionIcon />} />
        <SidebarItem to="/commission" button text="Comissão" icon={<MonetizationOnIcon />} />
      </div>
      {user && (
        <SidebarItem
          className={classes.account}
          to="/account"
          icon={
            user ? (
              <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
            ) : (
              <Avatar className={classes.avatar}>U</Avatar>
            )
          }
          text={user ? user.name : 'Carregando...'}
        />
      )}
    </Drawer>
  );
};

export default Sidebar;
