import React from 'react';
import Typography from '@material-ui/core/es/Typography/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { useNavigate } from 'react-router-dom';
import { Customer } from 'types/customer';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'grid',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    minHeight: 135,
  },
  listItemRazao: {
    marginBottom: 5,
    maxWidth: '93%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  N: {
    backgroundColor: '#6bc720',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    fontSize: 12,
    marginLeft: 5,
  },
  S: {
    backgroundColor: '#ff8100',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    fontSize: 12,
    marginLeft: 5,
  },
  labelFirstColunm: {
    width: 80,
    display: 'inline-block',
  },
  clienteState: {
    textAlign: 'left',
  },
  labelSecondColunm: {
    [theme.breakpoints.down('xs')]: {
      width: 230,
    },
  },
  typographyFlex: {
    display: 'flex',
  },
}));

const situation = {
  N: 'Ativo',
  S: 'Bloqueado',
};

interface CustomerListItemProps {
  customer: Customer;
}

const CustomerListItem: React.FC<CustomerListItemProps> = ({ customer }) => {
  const classes = styles();
  const navigate = useNavigate();

  return (
    <ListItem onClick={() => navigate(`/customers/${customer.idCliente}`)} button className={classes.listItem}>
      <Typography variant="caption">
        {customer.idCliente} / <strong className={classes[customer.situacao]}>{situation[customer.situacao]}</strong>
      </Typography>
      <Typography className={classes.listItemRazao} variant="h6">
        {customer.razao}
      </Typography>
      <Typography className={classes.typographyFlex} variant="caption">
        <span className={classes.labelFirstColunm}>Fantasia:</span>
        <span className={classes.labelSecondColunm}>{customer.fantasia}</span>
      </Typography>
      <Typography variant="caption">
        <span className={classes.labelFirstColunm}>Cidade:</span>
        {customer.cidade}
      </Typography>
      <Typography className={classes.typographyFlex} variant="caption">
        <span className={classes.labelFirstColunm}>Endereço:</span>
        <span className={classes.labelSecondColunm}>
          {customer.endereco}, {customer.numero && customer.numero + ','} {customer.bairro}
        </span>
      </Typography>
    </ListItem>
  );
};

export default CustomerListItem;
