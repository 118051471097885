import React from 'react';
import UserDataToken from 'helpers/UserDataToken';

// eslint-disable-next-line react/display-name
const withUserData = Component => props => {
  const userData = new UserDataToken();
  return <Component {...props} userData={userData.dataToken.data} />;
};

/*
const _withUserData = Component => {
    return class ComponentWrapped extends React.PureComponent{
        render() {
            const userData = new UserDataToken();
            return(
                <Component {...this.props} />
            )
        }
    }
};

const __withUserData = Component => {
    const userData = new UserDataToken();
    return props => (
        <Component {...props} userData={userData.dataToken.data} />
    )
};
*/

export { withUserData };
