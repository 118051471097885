import React, { useMemo } from 'react';
import { Chip, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import { useSelector } from 'store/selector';
import CommissionChart from './CommissionChart';
import DashboardLoading from './DashboardLoading';
import { useTotalCommissionFetch } from './hooks/useTotalCommissionFetch';
import { useTotalOrdersFetch } from './hooks/useTotalOrdersFetch';
import SalesChart from './SalesChart';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const styles = makeStyles({
  paper: {
    padding: 15,
    paddingBottom: 0,
    position: 'relative',
  },

  DashItemDescription: {
    padding: '7px 0 0 0',
  },
  chip: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  colorChip: {
    backgroundColor: '#ffc107',
  },
  chipRoot: {
    textTransform: 'lowercase',
    borderRadius: 4,
    height: 25,
  },
  chipLabel: {
    paddingLeft: 8,
    paddingRight: 8,
  },

  detail: {
    marginTop: 5,
    padding: '6px 0',
    borderTop: '1px solid #eee',
  },
  percentCommission: {
    fontSize: 18,
  },
  row: {
    marginBottom: 10,
  },
});

const Dashboard: React.FC = () => {
  const classes = styles();

  const user = useSelector(state => state.user);

  const { total: ordersTotal, loading: ordersLoading } = useTotalOrdersFetch();
  const { loading: commissionLoading, percent: commissionPercent, total: commissionTotal } = useTotalCommissionFetch();

  const formattedOrdersTotal = useMemo(() => moneyFormat(ordersTotal), [ordersTotal]);

  const formattedCommissionTotal = useMemo(() => moneyFormat(commissionTotal), [commissionTotal]);
  const formatteCommissionPercent = useMemo(() => percentFormat(commissionPercent), [commissionPercent]);

  const today = format(new Date(), "MMMM '/' yy", { locale: ptBR });

  return (
    <>
      <Appbar title="Dashboard" />
      <Grid container spacing={0}>
        <Grid item className={classes.row}>
          <Typography variant="h6">
            Olá <strong>{user?.name}</strong>
          </Typography>
          <Typography variant="caption">Seja bem-vindo ao PedidoWeb</Typography>
        </Grid>

        <Grid className={classes.row} container spacing={2}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            {ordersLoading ? (
              <DashboardLoading />
            ) : (
              <Paper className={classes.paper}>
                <Typography>Pedidos</Typography>
                <Typography variant="h5" className={classes.DashItemDescription}>
                  {formattedOrdersTotal}
                  <Chip
                    classes={{
                      root: classes.chipRoot,
                      label: classes.chipLabel,
                      colorPrimary: classes.colorChip,
                    }}
                    label={today}
                    color="primary"
                    className={classes.chip}
                  />
                </Typography>
                <div className={classes.detail}>
                  <Typography variant="caption">
                    Somente <strong>venda</strong>
                  </Typography>
                </div>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={3} xl={3}>
            {commissionLoading ? (
              <DashboardLoading />
            ) : (
              <Paper className={classes.paper}>
                <Typography>Comissão</Typography>
                <Typography variant="h5" className={classes.DashItemDescription}>
                  {formattedCommissionTotal}
                  <span className={classes.percentCommission}> ({formatteCommissionPercent})</span>
                  <Chip
                    classes={{
                      root: classes.chipRoot,
                      label: classes.chipLabel,
                      colorPrimary: classes.colorChip,
                    }}
                    label={today}
                    color="primary"
                    className={classes.chip}
                  />
                </Typography>
                <div className={classes.detail}>
                  <Typography variant="caption">Faturados no mês corrente</Typography>
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <SalesChart />
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CommissionChart />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Dashboard;
