import React, { useState } from 'react';
import { AppProvider } from 'hooks/useApp';
import AuthProvider from 'providers/auth';
import { Provider } from 'react-redux';
import { store } from 'store';
import MessagingProvider from 'providers/messaging';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'config/theme';
import Routes from './routes/Routes';
import { useWindowSize } from 'hooks/useWindowSize';
import { BrowserRouter } from 'routes/BrowserRouter';
import history from 'services/history';
import ListStyleProvider from 'providers/listStyle';

const App: React.FC = () => {
  const { width: windowWidth, isMobile } = useWindowSize();

  const [isOpenedMenu, setIsOpenedMenu] = useState(!isMobile);

  function handleOpenMenu() {
    setIsOpenedMenu(state => !state);
  }

  return (
    <BrowserRouter history={history}>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <MessagingProvider>
              <AppProvider
                value={{
                  isMobile,
                  isOpenedMenu,
                  handleOpenMenu,
                  windowWidth,
                }}
              >
                <ListStyleProvider>
                  <Routes />
                </ListStyleProvider>
              </AppProvider>
            </MessagingProvider>
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
