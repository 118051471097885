import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  preLoading1: {
    padding: 6,
    width: 150,
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  preLoading2: {
    height: 27,
    width: 250,
    backgroundColor: '#eee',
    marginBottom: 20,
  },
  preLoading3: {
    padding: 16,
    backgroundColor: '#eee',
    marginBottom: 10,
    marginTop: 20,
    width: 50,
    borderRadius: 16,
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: '4px',
  },
  paper: {
    padding: '0px 10px',
  },
  list: {
    paddingTop: 0,
  },
};

class TableLoading extends React.Component {
  render() {
    const { classes } = this.props;
    let cont;
    const content = [];

    for (cont = 0; cont < 7; cont++) {
      content[cont] = (
        <ListItem key={cont} className={classes.listItem}>
          <div className={`animated-background ${classes.preLoading1}`} />
          <div className={`animated-background ${classes.preLoading2}`} />
          <div className={`animated-background ${classes.preLoading1}`} />
          <div className={`animated-background ${classes.preLoading1}`} />
        </ListItem>
      );
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List classes={{ root: classes.list }} component={'nav'}>
            {content}
          </List>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TableLoading);
