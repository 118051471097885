import React from 'react';
import { Typography } from '@material-ui/core';
import OrderStockSearchList from './OrderStockSearchList';

interface OrderStockSearchProps {
  handleProductStockSelect(product: any): void;
  products: any[];
}

const OrderStockSearch: React.FC<OrderStockSearchProps> = ({ handleProductStockSelect, products }) => {
  return (
    <>
      <Typography variant={'caption'}>Escolha um produto abaixo</Typography>
      <OrderStockSearchList handleProductStockSelect={handleProductStockSelect} products={products} />
    </>
  );
};

export default OrderStockSearch;
