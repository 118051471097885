import React from 'react';
import { FormControlLabel, IconButton, Switch, Typography } from '@material-ui/core';
import { useSelector } from 'store/selector';
import { Done } from '@material-ui/icons';
import { Product } from 'types/product';

interface OrderStockActionProps {
  showStock: any;
  handleChange(): void;
  handleAddProductToStock(): void;
  selectedProduct: Product | null;
}

const OrderStockAction: React.FC<OrderStockActionProps> = ({
  showStock,
  handleChange,
  handleAddProductToStock,
  selectedProduct,
}) => {
  const stock = useSelector(state => state.stock);

  function handleClick() {
    handleAddProductToStock();
  }

  return (
    <>
      {stock.products.length > 0 && !selectedProduct && (
        <FormControlLabel
          control={<Switch color="secondary" value={showStock} checked={showStock} onChange={handleChange} />}
          label={
            <Typography style={{ color: '#fff' }} variant="caption">
              Estoque
            </Typography>
          }
        />
      )}
      {selectedProduct && (
        <IconButton onClick={handleClick} color="inherit" type="button">
          <Done />
        </IconButton>
      )}
    </>
  );
};

export default OrderStockAction;
