import React from 'react';
import { makeStyles, Typography, ListItem } from '@material-ui/core';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/NumberFormat';

const styles = makeStyles(theme => ({
  content: {
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },

  chipPrice: {
    marginTop: 10,
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer',
    border: '2px solid #eee',
    maxWidth: '550px',
  },
  productName: {
    marginBottom: 7,
  },
  label: {
    display: 'inline-block',
    width: '120px',
  },
  listItemWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 4,
  },
  checkCircleIcon: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '50%',
  },
}));

interface OrderProductsListProps {
  product: any;
}

const OrderProductsList: React.FC<OrderProductsListProps> = product => {
  const classes = styles();

  return (
    <ListItem className={classes.listItem}>
      <Typography className={classes.productName} variant="h6">
        {product.product.productId} - {product.product.description}
      </Typography>

      <Typography>
        <span className={classes.label}>Quantidade: </span>
        {product.product.quantity}
        {product.product.unit} ({numberFormat(product.product.secondQuantity)}
        {product.product.secondUnit})
      </Typography>

      <Typography>
        <span className={classes.label}>Desconto: </span>
        {product.product.discount} <span>%</span>
      </Typography>

      <Typography>
        <span className={classes.label}>Preço: </span>
        {moneyFormat(product.product.price)}
      </Typography>

      <Typography>
        <span className={classes.label}>Comissão: </span>
        {moneyFormat(product.product.vlCommission)} ({percentFormat(product.product.commission)})
      </Typography>

      <Typography>
        <span className={classes.label}>Total: </span>
        <strong>{moneyFormat(product.product.totalFinal)}</strong>
      </Typography>
    </ListItem>
  );
};

export default OrderProductsList;
