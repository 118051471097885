import { Customer } from 'types/customer';
import { Product } from 'types/product';
import { OrderActions } from './types';

type AddOrderProductParam = {
  product: Product;
  commission: number;
  quantity: number;
  discount: number;
};

export function orderChange(key: string, value: any): OrderActions {
  return {
    type: 'ORDER_CHANGE',
    key,
    value,
  };
}

export function setOrder(order: any): OrderActions {
  return {
    type: 'SET_ORDER',
    order,
  };
}

export function addOrderProduct({ commission, product, quantity, discount }: AddOrderProductParam): OrderActions {
  return {
    type: 'ADD_ORDER_PRODUCT',
    commission,
    product,
    quantity,
    discount,
  };
}

export function deleteOrderProduct(productIds: string[]): OrderActions {
  return {
    type: 'DELETE_ORDER_PRODUCT',
    productIds,
  };
}

export function setCustomer(customer: Customer): OrderActions {
  return {
    type: 'SET_ORDER_CUSTOMER',
    customer,
  };
}

export function setNewOrder(): OrderActions {
  return {
    type: 'SET_NEW_ORDER',
  };
}
