import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Loading from 'components/loading/Loading';
import SearchLoading from 'components/loading/SearchLoading';
import OrderViewTotal from './viewTotal/OrderViewTotal';
import OrderAddProduct from './form/AddProduct';
import Appbar from 'components/appbar/Appbar';
import { useOrderReducer } from './reducer/reducer';
import { orderChange, addOrderProduct, setOrder, deleteOrderProduct, setCustomer } from './reducer/actions';
import { useMessaging } from 'providers/messaging';
import { api } from 'services/api';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import OrderAction from './action/OrderAction';
import OrderTabs from './tabs/OrderTabs';
import OrderStock from './stock/OrderStock';
import OrderBasic from './basic/OrderBasic';
import { Customer } from 'types/customer';
import { OrderProvider } from './hooks/useOrder';
import OrderMix from './mixModal/OrderMix';
import { OrderProduct, Product } from 'types/product';
import OrderProducts from './products/OrderProducts';
import EditProduct from './form/EditProduct';
import history from 'services/history';
import { setCustomerToStock } from 'store/modules/stock/actions';
import { useDispatch } from 'react-redux';

export type CheckDiscountParam = {
  productId: string;
  price: number;
  discount: number;
  quantity: number;
};

export type HandleAddOrEditProductParam = {
  product: Product;
  price: number;
  discount: number;
  quantity: number;
};

const styles = makeStyles(theme => ({
  action: {
    margin: '40px 0 20px',
  },
  button: {
    marginRight: 10,
  },
  paper: {
    width: '100%',
    margin: '0 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0px',
    },
  },
  title: {
    padding: 20,
    borderBottom: '1px solid #ccc',
  },
  tabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
  },
  appbar: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

let timer: NodeJS.Timeout;

const Ordercopy: React.FC = () => {
  const classes = styles();
  const [loadingSendOrder, setLoadingSendOrder] = useState(false);
  const [checkDiscountLoading, setCheckDiscountLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [order, dispatch] = useOrderReducer();
  const [stock, setStock] = useState<any[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [showTotal, setShowTotal] = useState(false);
  const [productToAdd, setProductToAdd] = useState<Product | null>(null);
  const [productToEdit, setProductToEdit] = useState<OrderProduct | null>(null);
  const [isOpenedStockModal, setIsOpenedStockModal] = useState(false);
  const [isOpenedAddProductModal, setIsOpenedAddProductModal] = useState(false);
  const [isOpenedEditProductModal, setIsOpenedEditProductModal] = useState(false);
  const [isOpenedMixModal, setIsOpenedMixModal] = useState(false);
  const [productsMix, setProductsMix] = useState<any[]>([]);
  const messaging = useMessaging();
  const [selectedProducts, setSelectedProducts] = useState<OrderProduct[]>([]);
  const navigate = useNavigate();
  const stockDispatch = useDispatch();

  const loadProductMix = useCallback(() => {
    api
      .get('/vendas/mix', {
        params: {
          idCliente: order.customer?.idCliente,
        },
      })
      .then(response => {
        setProductsMix(response.data.data || []);
      });
  }, [order.customer]);

  useEffect(() => {
    localStorage.setItem('order', JSON.stringify(order));
  }, [order]);

  useEffect(() => {
    if (!order.customer) {
      return;
    }

    stockDispatch(setCustomerToStock(order.customer));
  }, [order.customer, stockDispatch]);

  useEffect(() => {
    const _stock = localStorage.getItem('stock');

    if (_stock) {
      setStock(JSON.parse(_stock));
    }
  }, []);

  useEffect(() => {
    if (!order.customer?.idCliente) {
      return;
    }

    loadProductMix();
  }, [order.customer, loadProductMix]);

  function handleOrderChange(key: string, value: any) {
    clearTimeout(timer);

    if (key === 'desconto') {
      _checkDiscountPayment(order.idCondicaoPagamento, parseFloat(value));
    }

    if (key === 'idCondicaoPagamento') {
      _checkDiscountPayment(value, order.desconto);
    }

    dispatch(orderChange(key, value));
  }

  function _checkDiscountPayment(idCondicaoPagamento: string, desconto: number) {
    clearTimeout(timer);

    if (!desconto) {
      return;
    }

    timer = setTimeout(() => {
      setCheckDiscountLoading(true);
      api
        .get('/vendas/checkDiscountPayment', { params: { idCondicaoPagamento, desconto } })
        .catch(err => {
          messaging.handleOpen(err.response.data.message ?? 'Não foi possível verificar o desconto');
          dispatch(orderChange('desconto', 0));
        })
        .finally(() => setCheckDiscountLoading(false));
    }, 500);
  }

  function handleSelectProducts(product: OrderProduct) {
    setSelectedProducts(state =>
      state.some(item => item.productId === product.productId)
        ? state.filter(item => item.productId !== product.productId)
        : [...state, product]
    );
  }

  function handleTabChange(value: number) {
    setTabIndex(value);
  }

  async function checkDiscount({ productId, discount, quantity, price }: CheckDiscountParam) {
    return await api.get('/vendas/checkDesconto', {
      params: {
        codigo: productId,
        desconto: discount,
        qtd: quantity,
        preco: price,
        idCliente: order.customer?.idCliente,
        serie: order.idRateio,
        descontoPedido: order.desconto,
        idOperacao: order.idOperacao,
        idCondicaoPagamento: order.idCondicaoPagamento,
      },
    });
  }

  async function handleAddOrEditProduct({ quantity, discount, price, product }: HandleAddOrEditProductParam) {
    try {
      setSaving(true);

      const response = await checkDiscount({ productId: product.productId, quantity, discount, price });

      dispatch(
        addOrderProduct({
          product: { ...product, price },
          commission: response.data.commission,
          quantity,
          discount,
        })
      );

      handleClearSelectedProducts();
    } catch (error: any) {
      messaging.handleOpen('Não foi possível salvar');
      throw error;
    } finally {
      setSaving(false);
    }
  }

  function handleSetProductToEdit() {
    if (!selectedProducts.length) {
      return;
    }

    const [product] = selectedProducts;

    api
      .get('/vendas/getPrice', {
        params: {
          idProduto: product.productId,
          idCliente: order.customer?.idCliente,
          serie: order.idRateio,
        },
      })
      .then(response => {
        setIsOpenedEditProductModal(true);

        setProductToEdit({
          ...product,
          fator: response.data.fator,
          price: response.data.price,
          secondUnit: response.data.secondUnit,
        });
      })
      .catch(err =>
        messaging.handleOpen(err.response ? err.response.data.message : 'Não foi possível recuperar o preço do produto')
      );
  }

  function handleItemDelete() {
    dispatch(deleteOrderProduct(selectedProducts.map(product => product.productId)));
    handleClearSelectedProducts();
    messaging.handleOpen('Item removido', handleUndoDelete);
  }

  function handleUndoDelete() {
    setOrder(state => ({
      ...state,
      items: history,
    }));
  }

  function handleSetCustomer(customer: Customer) {
    dispatch(setCustomer(customer));
  }

  function removeFromLocalStorage() {
    localStorage.removeItem('order');
    localStorage.removeItem('stock');
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setLoadingSendOrder(true);

    const totalproducts = order.items.reduce((sum, product) => sum + product.totalbruto, 0);

    const total = order.items.reduce((sum, product) => sum + product.totalfinal, 0);

    order.items.map(item => {
      return {
        id: item.productId,
        qtd: item.quantity,
        segundaQtd: item.secondQuantity,
        desconto: item.discount,
        unidade: item.unit,
        segundaUnidade: item.secondUnit,
        comissao: item.commission,
        vlDesconto: item.discount,
        preco: item.price,
      };
    });

    const products = order.items.map(item => ({
      id: item.productId,
      qtd: item.quantity,
      segundaQtd: item.secondQuantity,
      desconto: item.discount,
      unidade: item.unit,
      segundaUnidade: item.secondUnit,
      comissao: item.commission,
      idOperacao: order.idOperacao,
      vlDesconto: item.vlDiscount,
      preco: item.price,
    }));

    const payload = {
      idPedido: uuid(),
      idCliente: order.customer?.idCliente,
      idRateio: order.idRateio,
      percentualDesconto: order.desconto,
      idOperacao: order.idOperacao,
      idFormaPagamento: order.idFormaPagamento,
      idCondicaoPagamento: order.idCondicaoPagamento,
      observacao: order.observacao,
      totalProdutos: totalproducts,
      total,
      items: JSON.stringify(products),
      idRepresentante: localStorage.getItem('idRepresentante'),
    };

    const form = new FormData();

    Object.keys(payload).forEach(key => form.append(key, payload[key]));

    api
      .post('/vendas/send', form)
      .then(() => {
        messaging.handleOpen('Pedido foi enviado!');
        removeFromLocalStorage();
        history.push('/orders');
      })
      .catch(err => messaging.handleOpen(err.response ? err.response.data.message : 'Não foi possível enviar'))
      .finally(() => setLoadingSendOrder(false));
  }

  function handleSelectAllProducts() {
    setSelectedProducts(order.items);
  }

  function handleClearSelectedProducts() {
    setSelectedProducts([]);
  }

  function handleShowTotal() {
    setShowTotal(state => !state);
  }

  function getTitle() {
    if (!selectedProducts.length) {
      return 'Vendas';
    }

    return `${selectedProducts.length} ${selectedProducts.length > 1 ? 'produtos' : 'produto'}`;
  }

  return (
    <OrderProvider
      value={{
        dispatch,
        order,
        stock,
        setStock,
        handleAddOrEditProduct,
        productsMix,
        setProductToAdd,
        productToAdd,
        setProductToEdit,
        productToEdit,
        selectedProducts,
        handleItemDelete,
        handleSelectProducts,
        saving,
        setIsOpenedAddProductModal,
        setIsOpenedMixModal,
        setIsOpenedStockModal,
        isOpenedMixModal,
      }}
    >
      <Appbar
        backAction={() => navigate('/orders')}
        title={getTitle()}
        ActionsComponent={
          <OrderAction
            listItemSelected={selectedProducts}
            handleSubmit={handleSubmit}
            handleListItemSelectAll={handleSelectAllProducts}
            handleShowTotal={handleShowTotal}
            handleSetProductToEdit={handleSetProductToEdit}
          />
        }
        Tab={<OrderTabs tabvalue={tabIndex} handleTabChange={handleTabChange} customer={undefined} />}
      />

      {checkDiscountLoading && <SearchLoading />}

      {loadingSendOrder && <Loading />}

      {isOpenedStockModal && <OrderStock onExited={() => setIsOpenedStockModal(false)} />}

      {showTotal && <OrderViewTotal order={order} onExited={handleShowTotal} />}

      {isOpenedAddProductModal && <OrderAddProduct onExited={() => setIsOpenedAddProductModal(false)} />}

      {isOpenedEditProductModal && <EditProduct onExited={() => setIsOpenedEditProductModal(false)} />}

      {isOpenedMixModal && <OrderMix onExited={() => setIsOpenedMixModal(false)} />}

      <Grid container spacing={0}>
        <div className={classes.paper}>
          {tabIndex === 0 ? (
            <OrderBasic
              handleSetCustomer={handleSetCustomer}
              handleOrderChange={handleOrderChange}
              idOperacao={order.idOperacao}
              idFormaPagamento={order.idFormaPagamento}
              idCondicaoPagamento={order.idCondicaoPagamento}
              idRateio={order.idRateio}
              desconto={order.desconto}
              observacao={order.observacao}
              items={order.items}
            />
          ) : (
            <OrderProducts products={order.items} />
          )}
        </div>
      </Grid>
    </OrderProvider>
  );
};

export default Ordercopy;
