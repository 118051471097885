import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

interface InvoicesActionsProps {
  handleOpenSearch(): void;
}

const InvoicesAction: React.FC<InvoicesActionsProps> = ({ handleOpenSearch }) => {
  return (
    <div>
      <Tooltip title="Pesquisa">
        <IconButton onClick={handleOpenSearch} color="inherit">
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default InvoicesAction;
