import React from 'react';
import OrderActionButton from './OrderActionButton';
import { useOrder } from '../hooks/useOrder';
import OrderActionButtonMore from './OrderActionButtonMore';
import DeleteIcon from '@material-ui/icons/Delete';
import IconCheck from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

interface OrderActionProps {
  handleSubmit(value: any): void;
  listItemSelected: any;
  handleListItemSelectAll(): void;
  handleShowTotal(): void;
  handleSetProductToEdit(): void;
}

const OrderAction: React.FC<OrderActionProps> = ({
  handleSubmit,
  handleListItemSelectAll,
  handleShowTotal,
  handleSetProductToEdit,
}) => {
  const { handleItemDelete, order, selectedProducts } = useOrder();

  return (
    <>
      {order.items.length > 0 && selectedProducts.length < 1 && (
        <OrderActionButton title="Enviar pedido" action={() => handleSubmit(selectedProducts)} icon={<IconCheck />} />
      )}

      {selectedProducts.length === 1 && (
        <OrderActionButton title="Editar" action={handleSetProductToEdit} icon={<EditIcon />} />
      )}

      {selectedProducts.length >= 1 && (
        <OrderActionButton title="Excluir selecionados" action={() => handleItemDelete()} icon={<DeleteIcon />} />
      )}

      <OrderActionButtonMore selectAll={handleListItemSelectAll} showTotal={handleShowTotal} />
    </>
  );
};

export default OrderAction;
