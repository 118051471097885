import React from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { useOrder } from '../hooks/useOrder';

const styles = makeStyles(theme => ({
  content: {
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
}));

const OrderNoProducts: React.FC = () => {
  const classes = styles();
  const { productsMix, order, setIsOpenedAddProductModal, setIsOpenedMixModal, setIsOpenedStockModal } = useOrder();

  return (
    <div className={classes.content}>
      <Typography variant="h4">Nenhum produto foi adicionado ao pedido</Typography>

      {order?.customer ? (
        <>
          <Typography variant="caption">O que você deseja fazer?</Typography>

          <ul>
            <li>
              <Button
                onClick={() => setIsOpenedAddProductModal(true)}
                variant="text"
                style={{ textTransform: 'none' }}
                color="primary"
              >
                Incluir um produto
              </Button>
            </li>

            {productsMix.length > 0 && (
              <li>
                <Button
                  onClick={() => setIsOpenedMixModal(true)}
                  style={{ textTransform: 'none' }}
                  variant="text"
                  color="primary"
                >
                  Carregar mix
                </Button>
              </li>
            )}

            <li>
              <Button
                onClick={() => setIsOpenedStockModal(true)}
                variant="text"
                style={{ textTransform: 'none' }}
                color="primary"
              >
                Lançar estoque
              </Button>
            </li>
          </ul>
        </>
      ) : (
        <Typography variant="caption">Você precisa escolher um cliente antes de incluir algum produto.</Typography>
      )}
    </div>
  );
};

export default OrderNoProducts;
